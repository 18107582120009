<template>
  <div class="wrapper">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="" name="1">
              <template slot="title">
                <div class="subTit">
                  <el-icon class="el-icon-files"> </el-icon>快捷菜单
                </div>
              </template>
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="2">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-collection-tag"></el-icon>订阅搜索
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.id, 'order')"
                  v-for="item in OrderSearchList"
                  :class="actived == item.id ? 'actived' : ''"
                  :key="item.id"
                >
                  {{ item.name }}
                  <div class="closed" @click.stop="delSearch(item.id)">
                    &times;
                  </div>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        v-loading="screenLoading"
        class="content-container clearfix scollDom"
        :class="collapsed ? 'screen' : ''"
        :style="
          !collapsed
            ? 'border:1px #e5e5e5 solid'
            : collapsed
            ? ''
            : 'border:0;max-height: 68vh;'
        "
      >
        <el-form class="normalFrom" @submit.native.prevent>
          <div class="clearfix" style="padding: 0 15px">
            <el-row :span="24">
              <el-col
                class="searchRow"
                style="display: flex; align-items: self-start"
              >
                <div class="fl" style="width: 100%">
                  <el-form-item label style="margin-bottom: 10px">
                    <el-input
                      class="search"
                      v-model="searchInfo.searchKey"
                      @keyup.enter.native="submitForm()"
                      placeholder="'请输入项目名称 / 姓名 / 公司名称，支持模糊搜索'
                      "
                    >
                      <el-button
                        type="primary"
                        slot="append"
                        @click="submitForm()"
                        >搜 索</el-button
                      >
                    </el-input>
                  </el-form-item>
                </div>

                <div class="other" style="width: 205px">
                  <span class="order" @click="orderSearch">订阅搜索</span>
                  <span
                    class="more"
                    @click="
                      isMoreItemShow = !isMoreItemShow;
                      loadTagList('人才');
                      loadColumnInList('行业');
                      loadColumnPosList();
                      loadColumnCityList('城市');
                    "
                    >{{ isMoreItemShow ? "收起" : "展开" }}更多条件<el-icon
                      :class="
                        isMoreItemShow
                          ? 'el-icon-arrow-up'
                          : 'el-icon-arrow-down'
                      "
                      style="margin-left: 2px"
                    ></el-icon>
                  </span>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-row style="width: 100%; background-color: #fff">
            <el-col
              class="searchPanel"
              style="padding-left: 0; padding-right: 0"
            >
              <el-form
                label-width="110px"
                :model="searchInfo"
                ref="searchInfo"
                class="searchInfo clearfix"
                :rules="rulesOfSearch"
              >
                <el-form-item label="项目名称：" prop="projectName">
                  <el-input
                    placeholder="支持模糊搜索"
                    v-model.trim="searchInfo.projectName"
                    class="searchInput"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm()"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="userName">
                  <el-input
                    placeholder="支持模糊搜索"
                    v-model.trim="searchInfo.userName"
                    class="searchInput"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm()"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="联系方式：" prop="contact">
                  <el-input
                    placeholder=""
                    v-model.trim="searchInfo.contact"
                    class="searchInput"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm()"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="操作顾问：" prop="consultantIds">
                  <el-cascader
                    style="width: 240px"
                    clearable
                    size="mini"
                    filterable
                    :show-all-levels="false"
                    :options="options2"
                    collapse-tags
                    v-model="searchInfo.consultantIds"
                    :props="props1"
                    @change="submitForm()"
                    :key="keyOfCreateUserIds"
                  ></el-cascader>
                  <el-checkbox
                    style="width: 100px; margin-left: 15px"
                    v-model="searchInfo.showDisabledUser"
                    @change="changeShowDisabledUser"
                    >停用账户</el-checkbox
                  >
                </el-form-item>
                <div v-show="isMoreItemShow" class="clearfix">
                   <div>
                    <el-col
                      class="transition-box clearfix"
                      style="
                        padding: 10px 0 0;
                        border-top: 1px solid #ededed;
                        border-bottom: 1px #eee solid;
                        background: #fafafa;
                      "
                    >
                      <el-col :span="24" style="padding-left: 0">
                        <el-col :span="24" style="padding: 0">
                          <el-form-item
                            label="现居住城市："
                            prop="nowLocations"
                            style="margin-bottom: 0"
                          >
                            <city-choose
                              v-model="searchInfo.nowLocations"
                              @mainSearch="submitForm"
                            ></city-choose>
                            <el-popover
                              placement="bottom"
                              trigger="click"
                              popper-class="moreCity"
                              width="250"
                            >
                              <el-cascader
                                :key="cascaderCityKey"
                                filterable
                                clearable
                                size="mini"
                                style="width: 100%; height: 34px"
                                @change="submitForm()"
                                v-model="searchInfo.nowLocations"
                                :show-all-levels="false"
                                :options="ColumnCityList"
                                collapse-tags
                                :props="cityProps"
                              ></el-cascader>
                              <span
                                style="margin-left: 15px"
                                slot="reference"
                                class="hoverColorChange"
                                @click="
                                  (isMoreCity = !isMoreCity), isMoreCityClk()
                                "
                                >更多
                                <i
                                  :class="{
                                    'el-icon-arrow-down': !isMoreCity,
                                    'el-icon-arrow-up': isMoreCity,
                                  }"
                                ></i
                              ></span>
                            </el-popover>
                          </el-form-item>
                        </el-col>
                      </el-col>
                      <el-col style="padding-left: 0">
                        <el-form-item
                          label="工作年限："
                          prop="workExperiences"
                          style="margin-bottom: 0"
                        >
                          <el-checkbox-group
                            class="radioGroupBtnPipe"
                            size="mini"
                            fill="#fff"
                            text-color="#e60012"
                            v-model="searchInfo.workExperiences"
                            @change="workExperienceChanged($event)"
                          >
                            <el-checkbox-button label="UnLimit"
                              >不限</el-checkbox-button
                            >
                            <el-checkbox-button label="OneToThree"
                              >1-3年</el-checkbox-button
                            >
                            <el-checkbox-button label="ThreeToFive"
                              >3-5年</el-checkbox-button
                            >
                            <el-checkbox-button label="FiveToTen"
                              >5-10年</el-checkbox-button
                            >
                            <el-checkbox-button label="TenMore"
                              >10年以上</el-checkbox-button
                            >
                          </el-checkbox-group>
                        </el-form-item>
                      </el-col>
                      <el-col
                        style="padding-left: 0; margin-bottom: 10px"
                        :span="20"
                      >
                        <el-form-item
                          label="教育经历："
                          prop="educations"
                          style="margin-bottom: 0"
                        >
                          <el-checkbox-group
                            class="radioGroupBtnPipe"
                            size="mini"
                            fill="#fff"
                            text-color="#e60012"
                            v-model="searchInfo.educations"
                            @change="educationsChanged($event)"
                          >
                            <el-checkbox-button label="UnLimit"
                              >不限</el-checkbox-button
                            >
                            <el-checkbox-button
                              label="本科及以上"
                            ></el-checkbox-button>
                            <el-checkbox-button
                              label="硕士及以上"
                            ></el-checkbox-button>
                            <el-checkbox-button
                              label="博士及以上"
                            ></el-checkbox-button>
                            <el-checkbox-button
                              label="大专及以上"
                            ></el-checkbox-button>
                            <el-checkbox-button
                              label="高中及以下"
                            ></el-checkbox-button>
                          </el-checkbox-group>
                          <el-checkbox-group
                            class="radioGroupBtnPipe"
                            size="mini"
                            fill="#fff"
                            text-color="#e60012"
                            v-model="searchInfo.checkSchoolType"
                            @change="educationsChanged($event)"
                          >
                            <el-checkbox-button
                              label="985/211"
                            ></el-checkbox-button>
                          </el-checkbox-group>
                          <el-checkbox-group
                            class="radioGroupBtnPipe"
                            size="mini"
                            fill="#fff"
                            text-color="#e60012"
                            v-model="searchInfo.is_tongzhao"
                            @change="educationsChanged($event)"
                          >
                            <el-checkbox-button
                              label="统招"
                            ></el-checkbox-button>
                          </el-checkbox-group>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :span="24"
                        style="padding-left: 0; padding-right: 0"
                      >
                        <el-col
                          :span="24"
                          style="padding: 0 0 0 0; width: calc(100% - 24px)"
                          class="s_from_item"
                        >
                          <el-col :span="8">
                            <el-form-item
                              label="姓名："
                              v-if="fromPro"
                              prop="userName"
                            >
                              <el-input
                                placeholder="支持模糊搜索"
                                v-model.trim="searchInfo.userName"
                                class="searchInput"
                                style="width: 100%"
                              >
                                <template slot="suffix">
                                  <el-icon
                                    class="el-icon-search"
                                    @click.native="submitForm()"
                                  ></el-icon>
                                </template>
                              </el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="联系方式："
                              v-if="fromPro"
                              prop="contact"
                            >
                              <el-input
                                placeholder=""
                                v-model.trim="searchInfo.contact"
                                class="searchInput"
                                style="width: 100%"
                              >
                                <template slot="suffix">
                                  <el-icon
                                    class="el-icon-search"
                                    @click.native="submitForm()"
                                  ></el-icon>
                                </template>
                              </el-input>
                            </el-form-item>
                          </el-col>
                        </el-col>
                        <el-col
                          :span="24"
                          style="padding: 0 0 20px 0; width: calc(100% - 24px)"
                          class="s_from_item"
                        >
                          <el-col :span="8">
                            <el-form-item
                              label="联系状态："
                              prop="contactStatus"
                            >
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.contactStatus"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option
                                  label="无状态"
                                  value="无状态"
                                ></el-option>
                                <el-option
                                  label="联系中"
                                  value="联系中"
                                ></el-option>
                                <el-option
                                  label="推荐"
                                  value="推荐"
                                ></el-option>
                                <el-option
                                  label="面试"
                                  value="面试"
                                ></el-option>
                                <el-option
                                  label="待定"
                                  value="待定"
                                ></el-option>
                                <el-option
                                  label="不合适"
                                  value="不合适"
                                ></el-option>
                                <el-option
                                  label="不考虑"
                                  value="不考虑"
                                ></el-option>
                                <el-option
                                  label="黑名单"
                                  value="黑名单"
                                ></el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>

                          <el-col :span="8">
                            <el-form-item
                              label="项目状态："
                              prop="projectStatus"
                              v-if="!this.fromPro"
                            >
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.projectStatus"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option
                                  label="进行中"
                                  value="进行中"
                                ></el-option>
                                <el-option
                                  label="成功"
                                  value="成功"
                                ></el-option>
                                <el-option
                                  label="失败"
                                  value="失败"
                                ></el-option>
                                <el-option
                                  label="取消"
                                  value="取消"
                                ></el-option>
                                <el-option
                                  label="暂停"
                                  value="暂停"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="职能：" prop="functionIds">
                              <el-cascader
                                :key="isResouceShow"
                                style="width: 100%"
                                clearable
                                @change="submitForm()"
                                size="mini"
                                filterable
                                :show-all-levels="false"
                                :options="ColumnPosList"
                                collapse-tags
                                v-model="searchInfo.functionIds"
                                :props="props"
                              ></el-cascader> </el-form-item
                          ></el-col>

                          <el-col :span="8">
                            <el-form-item
                              label="职位名称："
                              prop="title"
                              style="position: relative"
                            >
                              <el-input
                                @focus="isShowTitle = true"
                                @blur="blurTitle($event)"
                                class="input-with-select appandSelect"
                                v-model.trim="searchInfo.title"
                                style="width: 100%"
                                placeholder="支持模糊搜索"
                              >
                                <el-select
                                  v-model="searchInfo.isAllTitle"
                                  slot="prepend"
                                  placeholder="请选择"
                                >
                                  <el-option
                                    label="当前职位"
                                    :value="false"
                                  ></el-option>
                                  <el-option
                                    label="全部职位"
                                    :value="true"
                                  ></el-option>
                                </el-select>
                              </el-input>
                              <el-button
                                v-show="isShowTitle"
                                style="
                                  position: absolute;
                                  padding: 8px;
                                  top: 2px;
                                  z-index: 999;
                                  background: #ff3f3f;
                                  color: white;
                                "
                                icon="el-icon-search"
                                @click="submitForm()"
                              ></el-button> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="公司名称："
                              prop="company"
                              style="position: relative"
                            >
                              <el-input
                                @focus="isShowCompany = true"
                                @blur="blurCompany($event)"
                                class="input-with-select appandSelect"
                                v-model="searchInfo.company"
                                style="width: 100%"
                                placeholder="支持模糊搜索"
                              >
                                <el-select
                                  v-model="searchInfo.isAllCompany"
                                  slot="prepend"
                                  placeholder="请选择"
                                >
                                  <el-option
                                    label="当前公司"
                                    :value="false"
                                  ></el-option>
                                  <el-option
                                    label="全部公司"
                                    :value="true"
                                  ></el-option>
                                </el-select>
                              </el-input>
                              <el-button
                                v-show="isShowCompany"
                                style="
                                  position: absolute;
                                  padding: 8px;
                                  top: 2px;
                                  background: #ff3f3f;
                                  color: white;
                                "
                                icon="el-icon-search"
                                @click="submitForm()"
                              ></el-button> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="年龄：" class="delFocus">
                              <div style="width: 100%">
                                <el-form-item
                                  label-width="0"
                                  prop="minAge"
                                  style="
                                    width: 49%;
                                    display: inline-block;
                                    margin-bottom: 0;
                                    margin-right: 0;
                                  "
                                >
                                  <el-input
                                    class="appendInput"
                                    v-model.trim.number="searchInfo.minAge"
                                    @blur="submitForm()"
                                    ><span slot="suffix">岁</span></el-input
                                  >
                                </el-form-item>
                                <span
                                  style="
                                    display: inline-block;
                                    height: 32px;
                                    float: left;
                                    line-height: 32px;
                                    width: 2%;
                                    text-align: center;
                                  "
                                >
                                  -
                                </span>
                                <el-form-item
                                  label-width="0"
                                  prop="maxAge"
                                  style="
                                    width: 49%;
                                    display: inline-block;
                                    margin-bottom: 0;
                                    margin-right: 0;
                                  "
                                >
                                  <el-input
                                    class="appendInput"
                                    v-model.trim.number="searchInfo.maxAge"
                                    @blur="submitForm()"
                                    ><span slot="suffix">岁</span></el-input
                                  >
                                </el-form-item>
                              </div>
                            </el-form-item></el-col
                          >
                          <!-- <el-col :span="8">
                            <el-form-item label="行业：" prop="vocationIds">
                              <el-cascader
                                :key="isResouceShow"
                                clearable
                                size="mini"
                                filterable
                                :show-all-levels="false"
                                :options="ColumnInList"
                                collapse-tags
                                @change="submitForm()"
                                v-model="searchInfo.vocationIds"
                                :props="props"
                                style="width: 100%; height: 34px"
                              ></el-cascader> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="候选人类型："
                              prop="resumeType"
                            >
                              <el-select
                                clearable
                                placeholder="请选择"
                                v-model="searchInfo.resumeType"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option
                                  label="候选人"
                                  :value="1"
                                ></el-option>
                                <el-option
                                  label="coldcall"
                                  :value="2"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col> -->
                          <el-col :span="8">
                            <el-form-item label="性别：" prop="sex">
                              <el-select
                                clearable
                                placeholder="请选择"
                                v-model="searchInfo.sex"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option label="男" value="男"></el-option>
                                <el-option label="女" value="女"></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <!-- <el-col :span="8">
                            <el-form-item
                              label="第一学历："
                              prop="firstDegrees"
                            >
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.firstDegrees"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option
                                  label="初中"
                                  value="初中"
                                ></el-option>
                                <el-option
                                  label="高中"
                                  value="高中"
                                ></el-option>
                                <el-option
                                  label="中技"
                                  value="中技"
                                ></el-option>
                                <el-option
                                  label="中专"
                                  value="中专"
                                ></el-option>
                                <el-option
                                  label="大专"
                                  value="大专"
                                ></el-option>
                                <el-option
                                  label="本科"
                                  value="本科"
                                ></el-option>
                                <el-option
                                  label="硕士"
                                  value="硕士"
                                ></el-option>
                                <el-option
                                  label="博士"
                                  value="博士"
                                ></el-option>
                                <el-option
                                  label="博士后"
                                  value="博士后"
                                ></el-option>
                                <el-option label="MBA" value="MBA"></el-option>
                                <el-option
                                  label="其他"
                                  value="其他"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="求职状态：" prop="jobStatus">
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.jobStatus"
                                @change="submitForm()"
                                style="width: 100%"
                              >
                                <el-option
                                  label="在职看机会"
                                  value="在职看机会"
                                ></el-option>
                                <el-option
                                  label="在职不看机会"
                                  value="在职不看机会"
                                ></el-option>
                                <el-option
                                  label="离职"
                                  value="离职"
                                ></el-option>
                                <el-option
                                  label="应届毕业生"
                                  value="应届毕业生"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="期望行业："
                              prop="forwardVocations"
                            >
                              <el-cascader
                                :key="isResouceShow"
                                clearable
                                size="mini"
                                filterable
                                :show-all-levels="false"
                                :options="ColumnInList"
                                collapse-tags
                                @change="submitForm()"
                                v-model="searchInfo.forwardVocations"
                                :props="props"
                                style="width: 100%; height: 34px"
                              ></el-cascader> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="期望城市："
                              prop="forwardLocations"
                            >
                              <el-cascader
                                :key="isResouceShow"
                                filterable
                                clearable
                                size="mini"
                                @change="submitForm()"
                                style="width: 100%"
                                :options="ColumnCityList"
                                collapse-tags
                                :show-all-levels="false"
                                v-model="searchInfo.forwardLocations"
                                :props="cityProps"
                              ></el-cascader> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="期望职位："
                              prop="forwardTitle"
                            >
                              <el-input
                                class="searchInput"
                                v-model.trim="searchInfo.forwardTitle"
                                style="width: 100%"
                                placeholder="支持模糊搜索"
                              >
                                <template slot="suffix">
                                  <el-icon
                                    class="el-icon-search"
                                    @click.native="submitForm()"
                                  ></el-icon>
                                </template>
                              </el-input> </el-form-item
                          ></el-col> -->
                          <el-col :span="8">
                            <el-form-item label="简历编号：" prop="id">
                              <el-input
                                v-model.trim="searchInfo.id"
                                class="searchInput"
                                style="width: 100%"
                              >
                                <template slot="suffix">
                                  <el-icon
                                    class="el-icon-search"
                                    @click.native="submitForm()"
                                  ></el-icon>
                                </template>
                              </el-input> </el-form-item
                          ></el-col>
                          <!-- <el-col :span="8">
                            <el-form-item label="渠道：" prop="channels">
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.channels"
                                @change="submitForm()"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="(i, index) in channelList"
                                  :key="'cha' + index"
                                  :label="i.value"
                                  :value="i.value"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col> -->
                          <el-col :span="8">
                            <el-form-item label="候选人标签：" prop="remark">
                              <!--TagList-->
                              <el-select
                                filterable
                                clearable
                                multiple
                                collapse-tags
                                @change="submitForm()"
                                v-model="searchInfo.tagIds"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="i in TagList"
                                  :key="i.id"
                                  :label="i.name"
                                  :value="i.id"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <!-- <el-col :span="8">
                            <el-form-item label="期望月薪：" class="delFocus">
                              <div style="width: 100%">
                                <el-form-item
                                  label-width="0"
                                  prop="lowAimSalary"
                                  style="
                                    width: 49%;
                                    display: inline-block;
                                    margin-bottom: 0;
                                    margin-right: 0;
                                  "
                                >
                                  <el-input
                                    class="appendInput"
                                    v-model.number.trim="
                                      searchInfo.lowAimSalary
                                    "
                                    @change="submitForm()"
                                    ><span slot="suffix" class="appendInput"
                                      >元</span
                                    ></el-input
                                  >
                                </el-form-item>
                                <span
                                  style="
                                    display: inline-block;
                                    height: 32px;
                                    float: left;
                                    line-height: 32px;
                                    width: 2%;
                                    text-align: center;
                                  "
                                >
                                  -
                                </span>
                                <el-form-item
                                  label-width="0"
                                  prop="highAimSalary"
                                  style="
                                    width: 49%;
                                    display: inline-block;
                                    margin-bottom: 0;
                                    margin-right: 0;
                                  "
                                >
                                  <el-input
                                    class="appendInput"
                                    v-model.number.trim="
                                      searchInfo.highAimSalary
                                    "
                                    @change="submitForm()"
                                    ><span slot="suffix">元</span></el-input
                                  >
                                </el-form-item>
                              </div>
                            </el-form-item></el-col
                          > -->
                          <el-col :span="8">
                            <el-form-item
                              label="人才拥有者："
                              prop="ownerUserIds"
                            >
                              <el-select
                                filterable
                                style="width: 100%;height:34px"
                                clearable
                                v-model="searchInfo.ownerUserIds"
                                placeholder="请选择"
                                multiple
                                size="mini"
                                collapse-tags
                                @change="submitForm()"
                                popper-class="selectManager"
                              >
                                <el-option
                                  v-for="i in searchUserList"
                                  :key="i.id"
                                  :label="i.name"
                                  :value="i.id"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="人才创建时间："
                              label-width="110px"
                              prop="remark"
                            >
                              <el-date-picker
                                style="width: 100%"
                                v-model="timeVal"
                                value-format="yyyy-MM-dd"
                                @change="getTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="加入项目时间："
                              label-width="110px"
                              prop="joinStartTime"
                            >
                              <el-date-picker
                                style="width: 100%"
                                v-model="rangTime"
                                value-format="yyyy-MM-dd"
                                @change="getJoinTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="简历推荐时间："
                              label-width="110px"
                              prop="recommendStartTime"
                            >
                              <el-date-picker
                                style="width: 100%"
                                v-model="timeRecommendVal"
                                value-format="yyyy-MM-dd"
                                @change="getRecommendTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="客户1面时间："
                              label-width="110px"
                              prop="firstInterviewStartTime"
                            >
                              <el-date-picker
                                style="width: 100%"
                                v-model="timeFirstInterviewVal"
                                value-format="yyyy-MM-dd"
                                @change="getFirstInterviewTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="Offer时间："
                              label-width="110px"
                              prop="offerStartTime"
                            >
                              <el-date-picker
                                style="width: 100%"
                                v-model="timeOfferVal"
                                value-format="yyyy-MM-dd"
                                @change="getOfferTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="入职时间："
                              label-width="110px"
                              prop="entryStartTime"
                            >
                              <el-date-picker
                                style="width: 100%"
                                v-model="timeEntryVal"
                                value-format="yyyy-MM-dd"
                                @change="getEntryTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-col>
                    <!--<el-col
                      class="transition-box clearfix"
                      style="
                        padding: 10px 0 0;
                        border-top: 1px solid #ededed;
                        border-bottom: 1px #eee solid;
                        background: #fafafa;
                      "
                    >
                      <el-col :span="24" style="padding-left: 0">
                        <el-col :span="24" style="padding: 0">
                          <el-form-item
                            label="现居住城市："
                            prop="nowLocations"
                            style="margin-bottom: 0"
                          >
                            <city-choose
                              v-model="searchInfo.nowLocations"
                              @mainSearch="loadList"
                            ></city-choose>
                            <el-popover
                              placement="bottom"
                              trigger="click"
                              popper-class="moreCity"
                              width="250"
                            >
                              <el-cascader
                                :key="cascaderCityKey"
                                filterable
                                clearable
                                size="mini"
                                style="width: 100%; height: 34px"
                                @change="submitForm()"
                                v-model="searchInfo.nowLocations"
                                :show-all-levels="false"
                                :options="ColumnCityList"
                                collapse-tags
                                :props="cityProps"
                              ></el-cascader>
                              <span
                                style="margin-left: 15px"
                                slot="reference"
                                class="hoverColorChange"
                                @click="
                                  (isMoreCity = !isMoreCity), isMoreCityClk()
                                "
                                >更多
                                <i
                                  :class="{
                                    'el-icon-arrow-down': !isMoreCity,
                                    'el-icon-arrow-up': isMoreCity,
                                  }"
                                ></i
                              ></span>
                            </el-popover>
                          </el-form-item>
                        </el-col>
                      </el-col>
                      <el-col style="padding-left: 0">
                        <el-form-item
                          label="工作年限："
                          prop="workExperiences"
                          style="margin-bottom: 0"
                        >
                          <el-checkbox-group
                            class="radioGroupBtnPipe"
                            size="mini"
                            fill="#fff"
                            text-color="#e60012"
                            v-model="searchInfo.workExperiences"
                            @change="workExperienceChanged($event)"
                          >
                            <el-checkbox-button label="UnLimit"
                              >不限</el-checkbox-button
                            >
                            <el-checkbox-button label="OneToThree"
                              >1-3年</el-checkbox-button
                            >
                            <el-checkbox-button label="ThreeToFive"
                              >3-5年</el-checkbox-button
                            >
                            <el-checkbox-button label="FiveToTen"
                              >5-10年</el-checkbox-button
                            >
                            <el-checkbox-button label="TenMore"
                              >10年以上</el-checkbox-button
                            >
                          </el-checkbox-group>
                        </el-form-item>
                      </el-col>
                      <el-col
                        style="padding-left: 0; margin-bottom: 10px"
                        :span="20"
                      >
                        <el-form-item
                          label="教育经历："
                          prop="educations"
                          style="margin-bottom: 0"
                        >
                          <el-checkbox-group
                            class="radioGroupBtnPipe"
                            size="mini"
                            fill="#fff"
                            text-color="#e60012"
                            v-model="searchInfo.educations"
                            @change="educationsChanged($event)"
                          >
                            <el-checkbox-button label="UnLimit"
                              >不限</el-checkbox-button
                            >
                            <el-checkbox-button
                              label="本科及以上"
                            ></el-checkbox-button>
                            <el-checkbox-button
                              label="硕士及以上"
                            ></el-checkbox-button>
                            <el-checkbox-button
                              label="博士及以上"
                            ></el-checkbox-button>
                            <el-checkbox-button
                              label="大专及以上"
                            ></el-checkbox-button>
                            <el-checkbox-button
                              label="高中及以下"
                            ></el-checkbox-button>
                          </el-checkbox-group>
                          <el-checkbox-group
                            class="radioGroupBtnPipe"
                            size="mini"
                            fill="#fff"
                            text-color="#e60012"
                            v-model="searchInfo.checkSchoolType"
                            @change="educationsChanged($event)"
                          >
                            <el-checkbox-button
                              label="985/211"
                            ></el-checkbox-button>
                          </el-checkbox-group>
                          <el-checkbox-group
                            class="radioGroupBtnPipe"
                            size="mini"
                            fill="#fff"
                            text-color="#e60012"
                            v-model="searchInfo.is_tongzhao"
                            @change="educationsChanged($event)"
                          >
                            <el-checkbox-button
                              label="统招"
                            ></el-checkbox-button>
                          </el-checkbox-group>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :span="24"
                        style="padding-left: 0; padding-right: 0"
                      >
                        <el-col
                          :span="24"
                          style="padding: 0 0 0 0; width: calc(100% - 24px)"
                          class="s_from_item"
                        >
                          <el-col :span="8">
                            <el-form-item label="姓名：" prop="userName">
                              <el-input
                                placeholder="支持模糊搜索"
                                v-model.trim="searchInfo.userName"
                                class="searchInput"
                                style="width: 100%"
                              >
                                <template slot="suffix">
                                  <el-icon
                                    class="el-icon-search"
                                    @click.native="submitForm()"
                                  ></el-icon>
                                </template>
                              </el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="联系方式：" prop="contact">
                              <el-input
                                placeholder=""
                                v-model.trim="searchInfo.contact"
                                class="searchInput"
                                style="width: 100%"
                              >
                                <template slot="suffix">
                                  <el-icon
                                    class="el-icon-search"
                                    @click.native="submitForm()"
                                  ></el-icon>
                                </template>
                              </el-input>
                            </el-form-item>
                          </el-col>
                        </el-col>
                        <el-col
                          :span="24"
                          style="padding: 0 0 20px 0; width: calc(100% - 24px)"
                          class="s_from_item"
                        >
                          <el-col :span="8">
                            <el-form-item
                              label="联系状态："
                              prop="contactStatus"
                            >
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.contactStatus"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option
                                  label="无状态"
                                  value="无状态"
                                ></el-option>
                                <el-option
                                  label="联系中"
                                  value="联系中"
                                ></el-option>
                                <el-option
                                  label="推荐"
                                  value="推荐"
                                ></el-option>
                                <el-option
                                  label="面试"
                                  value="面试"
                                ></el-option>
                                <el-option
                                  label="待定"
                                  value="待定"
                                ></el-option>
                                <el-option
                                  label="不合适"
                                  value="不合适"
                                ></el-option>
                                <el-option
                                  label="不考虑"
                                  value="不考虑"
                                ></el-option>
                                <el-option
                                  label="黑名单"
                                  value="黑名单"
                                ></el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>

                          <el-col :span="8">
                            <el-form-item
                              label="项目状态："
                              prop="projectStatus"
                            >
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.projectStatus"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option
                                  label="进行中"
                                  value="进行中"
                                ></el-option>
                                <el-option
                                  label="成功"
                                  value="成功"
                                ></el-option>
                                <el-option
                                  label="失败"
                                  value="失败"
                                ></el-option>
                                <el-option
                                  label="取消"
                                  value="取消"
                                ></el-option>
                                <el-option
                                  label="暂停"
                                  value="暂停"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="职能：" prop="functionIds">
                              <el-cascader
                                :key="isResouceShow"
                                style="width: 100%"
                                clearable
                                @change="submitForm()"
                                size="mini"
                                filterable
                                :show-all-levels="false"
                                :options="ColumnPosList"
                                collapse-tags
                                v-model="searchInfo.functionIds"
                                :props="props"
                              ></el-cascader> </el-form-item
                          ></el-col>

                          <el-col :span="8">
                            <el-form-item
                              label="职位名称："
                              prop="title"
                              style="position: relative"
                            >
                              <el-input
                                @focus="isShowTitle = true"
                                @blur="blurTitle($event)"
                                class="input-with-select appandSelect"
                                v-model.trim="searchInfo.title"
                                style="width: 100%"
                                placeholder="支持模糊搜索"
                              >
                                <el-select
                                  v-model="searchInfo.isAllTitle"
                                  slot="prepend"
                                  placeholder="请选择"
                                >
                                  <el-option
                                    label="当前职位"
                                    :value="false"
                                  ></el-option>
                                  <el-option
                                    label="全部职位"
                                    :value="true"
                                  ></el-option>
                                </el-select>
                              </el-input>
                              <el-button
                                v-show="isShowTitle"
                                style="
                                  position: absolute;
                                  padding: 8px;
                                  top: 2px;
                                  z-index: 999;
                                  background: #ff3f3f;
                                  color: white;
                                "
                                icon="el-icon-search"
                                @click="submitForm()"
                              ></el-button> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="公司名称："
                              prop="company"
                              style="position: relative"
                            >
                              <el-input
                                @focus="isShowCompany = true"
                                @blur="blurCompany($event)"
                                class="input-with-select appandSelect"
                                v-model="searchInfo.company"
                                style="width: 100%"
                                placeholder="支持模糊搜索"
                              >
                                <el-select
                                  v-model="searchInfo.isAllCompany"
                                  slot="prepend"
                                  placeholder="请选择"
                                >
                                  <el-option
                                    label="当前公司"
                                    :value="false"
                                  ></el-option>
                                  <el-option
                                    label="全部公司"
                                    :value="true"
                                  ></el-option>
                                </el-select>
                              </el-input>
                              <el-button
                                v-show="isShowCompany"
                                style="
                                  position: absolute;
                                  padding: 8px;
                                  top: 2px;
                                  background: #ff3f3f;
                                  color: white;
                                "
                                icon="el-icon-search"
                                @click="submitForm()"
                              ></el-button> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="年龄：" class="delFocus">
                              <div style="width: 100%">
                                <el-form-item
                                  label-width="0"
                                  prop="minAge"
                                  style="
                                    width: 49%;
                                    display: inline-block;
                                    margin-bottom: 0;
                                    margin-right: 0;
                                  "
                                >
                                  <el-input
                                    class="appendInput"
                                    v-model.trim.number="searchInfo.minAge"
                                    @blur="submitForm()"
                                    ><span slot="suffix">岁</span></el-input
                                  >
                                </el-form-item>
                                <span
                                  style="
                                    display: inline-block;
                                    height: 32px;
                                    float: left;
                                    line-height: 32px;
                                    width: 2%;
                                    text-align: center;
                                  "
                                >
                                  -
                                </span>
                                <el-form-item
                                  label-width="0"
                                  prop="maxAge"
                                  style="
                                    width: 49%;
                                    display: inline-block;
                                    margin-bottom: 0;
                                    margin-right: 0;
                                  "
                                >
                                  <el-input
                                    class="appendInput"
                                    v-model.trim.number="searchInfo.maxAge"
                                    @blur="submitForm()"
                                    ><span slot="suffix">岁</span></el-input
                                  >
                                </el-form-item>
                              </div>
                            </el-form-item></el-col
                          >
                          <el-col :span="8">
                            <el-form-item label="行业：" prop="vocationIds">
                              <el-cascader
                                :key="isResouceShow"
                                clearable
                                size="mini"
                                filterable
                                :show-all-levels="false"
                                :options="ColumnInList"
                                collapse-tags
                                @change="submitForm()"
                                v-model="searchInfo.vocationIds"
                                :props="props"
                                style="width: 100%; height: 34px"
                              ></el-cascader> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="候选人类型："
                              prop="resumeType"
                            >
                              <el-select
                                clearable
                                placeholder="请选择"
                                v-model="searchInfo.resumeType"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option
                                  label="候选人"
                                  :value="1"
                                ></el-option>
                                <el-option
                                  label="coldcall"
                                  :value="2"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="性别：" prop="sex">
                              <el-select
                                clearable
                                placeholder="请选择"
                                v-model="searchInfo.sex"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option label="男" value="男"></el-option>
                                <el-option label="女" value="女"></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="第一学历："
                              prop="firstDegrees"
                            >
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.firstDegrees"
                                style="width: 100%"
                                @change="submitForm()"
                              >
                                <el-option
                                  label="初中"
                                  value="初中"
                                ></el-option>
                                <el-option
                                  label="高中"
                                  value="高中"
                                ></el-option>
                                <el-option
                                  label="中技"
                                  value="中技"
                                ></el-option>
                                <el-option
                                  label="中专"
                                  value="中专"
                                ></el-option>
                                <el-option
                                  label="大专"
                                  value="大专"
                                ></el-option>
                                <el-option
                                  label="本科"
                                  value="本科"
                                ></el-option>
                                <el-option
                                  label="硕士"
                                  value="硕士"
                                ></el-option>
                                <el-option
                                  label="博士"
                                  value="博士"
                                ></el-option>
                                <el-option
                                  label="博士后"
                                  value="博士后"
                                ></el-option>
                                <el-option label="MBA" value="MBA"></el-option>
                                <el-option
                                  label="其他"
                                  value="其他"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="求职状态：" prop="jobStatus">
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.jobStatus"
                                @change="submitForm()"
                                style="width: 100%"
                              >
                                <el-option
                                  label="在职看机会"
                                  value="在职看机会"
                                ></el-option>
                                <el-option
                                  label="在职不看机会"
                                  value="在职不看机会"
                                ></el-option>
                                <el-option
                                  label="离职"
                                  value="离职"
                                ></el-option>
                                <el-option
                                  label="应届毕业生"
                                  value="应届毕业生"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="期望行业："
                              prop="forwardVocations"
                            >
                              <el-cascader
                                :key="isResouceShow"
                                clearable
                                size="mini"
                                filterable
                                :show-all-levels="false"
                                :options="ColumnInList"
                                collapse-tags
                                @change="submitForm()"
                                v-model="searchInfo.forwardVocations"
                                :props="props"
                                style="width: 100%; height: 34px"
                              ></el-cascader> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="期望城市："
                              prop="forwardLocations"
                            >
                              <el-cascader
                                :key="isResouceShow"
                                filterable
                                clearable
                                size="mini"
                                @change="submitForm()"
                                style="width: 100%"
                                :options="ColumnCityList"
                                collapse-tags
                                :show-all-levels="false"
                                v-model="searchInfo.forwardLocations"
                                :props="cityProps"
                              ></el-cascader> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="期望职位："
                              prop="forwardTitle"
                            >
                              <el-input
                                class="searchInput"
                                v-model.trim="searchInfo.forwardTitle"
                                style="width: 100%"
                                placeholder="支持模糊搜索"
                              >
                                <template slot="suffix">
                                  <el-icon
                                    class="el-icon-search"
                                    @click.native="submitForm()"
                                  ></el-icon>
                                </template>
                              </el-input> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="简历编号：" prop="id">
                              <el-input
                                v-model.trim="searchInfo.id"
                                class="searchInput"
                                style="width: 100%"
                              >
                                <template slot="suffix">
                                  <el-icon
                                    class="el-icon-search"
                                    @click.native="submitForm()"
                                  ></el-icon>
                                </template>
                              </el-input> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="渠道：" prop="channels">
                              <el-select
                                multiple
                                collapse-tags
                                clearable
                                v-model="searchInfo.channels"
                                @change="submitForm()"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="(i, index) in channelList"
                                  :key="'cha' + index"
                                  :label="i.value"
                                  :value="i.value"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="候选人标签：" prop="remark">
                             
                              <el-select
                                filterable
                                clearable
                                multiple
                                collapse-tags
                                @change="submitForm()"
                                v-model="searchInfo.tagIds"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="i in TagList"
                                  :key="i.id"
                                  :label="i.name"
                                  :value="i.id"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item label="期望月薪：" class="delFocus">
                              <div style="width: 100%">
                                <el-form-item
                                  label-width="0"
                                  prop="lowAimSalary"
                                  style="
                                    width: 49%;
                                    display: inline-block;
                                    margin-bottom: 0;
                                    margin-right: 0;
                                  "
                                >
                                  <el-input
                                    class="appendInput"
                                    v-model.number.trim="
                                      searchInfo.lowAimSalary
                                    "
                                    @change="submitForm()"
                                    ><span slot="suffix" class="appendInput"
                                      >元</span
                                    ></el-input
                                  >
                                </el-form-item>
                                <span
                                  style="
                                    display: inline-block;
                                    height: 32px;
                                    float: left;
                                    line-height: 32px;
                                    width: 2%;
                                    text-align: center;
                                  "
                                >
                                  -
                                </span>
                                <el-form-item
                                  label-width="0"
                                  prop="highAimSalary"
                                  style="
                                    width: 49%;
                                    display: inline-block;
                                    margin-bottom: 0;
                                    margin-right: 0;
                                  "
                                >
                                  <el-input
                                    class="appendInput"
                                    v-model.number.trim="
                                      searchInfo.highAimSalary
                                    "
                                    @change="submitForm()"
                                    ><span slot="suffix">元</span></el-input
                                  >
                                </el-form-item>
                              </div>
                            </el-form-item></el-col
                          >
                          <el-col :span="8">
                            <el-form-item
                              label="人才拥有者："
                              prop="ownerUserIds"
                            >
                              <el-select
                                filterable
                                style="width: 100%"
                                clearable
                                v-model="searchInfo.ownerUserIds"
                                placeholder="请选择"
                                multiple
                                size="mini"
                                collapse-tags
                                @change="submitForm()"
                                popper-class="selectManager"
                              >
                                <el-option
                                  v-for="i in searchUserList"
                                  :key="i.id"
                                  :label="i.name"
                                  :value="i.id"
                                ></el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="8">
                            <el-form-item
                              label="人才创建时间："
                              label-width="110px"
                              prop="remark"
                            >
                              <el-date-picker
                                style="width: 100%"
                                v-model="timeVal"
                                value-format="yyyy-MM-dd"
                                @change="getTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-col>-->
                  </div> 
                </div>
              </el-form>
            </el-col>
          </el-row>
        </el-form>
        <div style="padding-bottom: 10px">
          <el-row :gutter="24">
            <el-col :span="24" class="table-top" style="margin-top: 10px">
              <el-dropdown class="mgl10" trigger="click">
                <el-button
                  :disabled="multipleSelection.length == 0"
                  type="default"
                  plain
                  size="mini"
                >
                  批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1" @click.native="addPro"
                    >加入项目</el-dropdown-item
                  >
                  <el-dropdown-item :command="2" @click.native="addToFavorite"
                    >加入文件夹</el-dropdown-item
                  >
                  <el-dropdown-item :command="3" @click.native="addTag"
                    >打标签</el-dropdown-item
                  >
                  <el-dropdown-item :command="4" @click.native="addEmail"
                    >发邮件</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>

              <el-button
                size="mini"
                class="mgl10"
                :disabled="!hasDownExport"
                @click="exportDown"
                >导出</el-button
              >
              <el-pagination
                :hide-on-single-page="searchInfo.page.total > 10 ? false : true"
                v-if="searchInfo.page.total > 0 && !listLoading"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchInfo.page.current"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="searchInfo.page.size"
                layout="total,prev, pager, next,sizes"
                :total="searchInfo.page.total"
              ></el-pagination>
            </el-col>
            <el-col :span="24">
              <div class="steps clearfix" style="border-left: 0">
                <div
                  class="step-item"
                  :class="item.isActive ? 'is-active' : ''"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="changeItem(item)"
                >
                  <div
                    v-if="item.status != '所有'"
                    style="padding-left: 14px"
                    class="text-overflows"
                    :title="
                      item.status + '：' + item.count + '/' + item.historyCount
                    "
                  >
                    {{ item.status }}：{{ item.count }}/<span
                      style="color: #999"
                      >{{ item.historyCount }}</span
                    >
                  </div>
                  <div v-else style="border-left: 1px #ececec solid">
                    {{ item.status }}：<span>{{ item.historyCount }}</span>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                  'border-top': '1px #e5e5e5 solid',
                }"
                v-horizontal-scroll
                :data="ListData"
                class="tabBorder custor"
                v-sticky="{ top: 0, parent: '.scollDom' }"
                style="margin-top: 0"
                v-loading="listLoading"
                ref="handSelectTest_multipleTable"
                @selection-change="handleSelectionChange"
                :row-style="tableColor"
              >
                <el-table-column
                  type="selection"
                  width="40"
                  align="center"
                  fixed
                ></el-table-column>
                <el-table-column
                  prop
                  label="操作"
                  fixed
                  align="left"
                  width="80"
                >
                  <template slot-scope="scope">
                    <el-popover
                      placement="right-start"
                      width="150"
                      popper-class="cantralPop"
                      trigger="click"
                      :open-delay="0"
                      :ref="`popContral-${scope.row.id}`"
                    >
                      <ul class="groupCls">
                        <li
                          v-for="(group, index) in handleOpts"
                          :key="'group_' + index"
                        >
                          <p>{{ group.value }}</p>
                          <ul class="itemCls">
                            <li
                              v-for="(item, _index) in group.options"
                              @click="
                                item.disabled || scope.row.goodNewsApproving
                                  ? ''
                                  : openDialog(
                                      [group.value, item.value],
                                      scope.row
                                    )
                              "
                              :class="
                                item.disabled || scope.row.goodNewsApproving
                                  ? 'disabled'
                                  : ''
                              "
                              :key="'item_' + _index"
                            >
                              {{ item.value }}
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <i
                        slot="reference"
                        @click="
                          scope.row.hasPermission == null ||
                          scope.row.hasPermission == false
                            ? ''
                            : handleCont(scope.row)
                        "
                        :class="{
                          desableIcon:
                            scope.row.hasPermission == null ||
                            scope.row.hasPermission == false,
                          Cactived: scope.row.cantralPopvisible,
                        }"
                        class="iconfont icon-Pl_cz unfold"
                      ></i>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  prop
                  width="150"
                  align="left"
                  fixed
                  class-name="canEdit"
                  show-overflow-tooltip
                >
                  <template slot="header">
                    姓名
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input size="mini" v-model="searchInfo.userName">
                            <el-button
                              slot="append"
                              icon="el-icon-search"
                              @click="submitForm()"
                            ></el-button>
                          </el-input>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <div style="display: flex">
                      <p
                        class="tabHref"
                        @click="goResumeFoo(scope.row.resumeId)"
                        @mouseenter="selectStyle(scope.row)"
                        @mouseleave="clearSelect"
                      >
                        {{ scope.row.userName ? scope.row.userName : "--" }}
                      </p>
                      <div v-if="scope.row.hasPermission">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="scope.row.isTop == 1 ? '取消置顶' : '置顶'"
                          placement="top"
                        >
                          <i
                            style="color: #526ecc; cursor: pointer"
                            class="heardSearch iconfont icon-zhiding1"
                            @click="handleTop(scope.row.isTop, scope.row.id)"
                          ></i>
                        </el-tooltip>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="left"
                  label="项目阶段"
                  fixed
                  :width="
                    searchInfo.status == '加入项目' ||
                    searchInfo.status == '成功'
                      ? '120px'
                      : '260px'
                  "
                  class-name="canEdit"
                >
                  <template slot="header">
                    项目阶段
                    <div
                      class="sortL"
                      :class="{
                        ascing: sortType == 'entryStatus' && sortCheck == 1,
                        descing: sortType == 'entryStatus' && sortCheck == 2,
                      }"
                      @click.stop="
                        sortWay(
                          sortType == 'entryStatus' &&
                            sortCheck &&
                            sortCheck == 1
                            ? 'desc'
                            : 'asc',
                          'entryStatus'
                        )
                      "
                    >
                      <i class="asc"></i>
                      <i class="desc"></i>
                    </div>
                    <el-popover
                        title="搜索"
                        popper-class="popperEdit"
                        placement="bottom"
                        trigger="click"
                      >
                        <i
                          slot="reference"
                          class="heardSearch el-icon-search"
                        ></i>
                        <div class="popcon">
                          <div class="popcon-input">
                            <el-select
                              multiple
                              collapse-tags
                              clearable
                              v-model="searchInfo.statusList"
                              style="width: 100%"
                              @change="submitForm()"
                            >
                              <el-option
                                v-for="(item, index) in statusListOption"
                                :key="index"
                                :value="item"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <el-popover
                      placement="bottom-start"
                      width="450"
                      trigger="hover"
                      :open-delay="1000"
                      popper-class="popperH"
                    >
                      <el-row :gutter="24">
                        <el-col :span="24" v-if="operationLogList.length > 0">
                          <div shadow="hover">
                            <div
                              class="statusTitle"
                              v-for="(item, index) in operationLogList"
                              :key="index"
                            >
                              <el-collapse accordion class="isborder">
                                <el-collapse-item class="foldStyle">
                                  <template slot="title">
                                    {{
                                      item.createdTime
                                        ? item.createdTime.substring(0, 16)
                                        : ""
                                    }}
                                    <span class="mgl10">{{
                                      item.creatorName
                                    }}</span>
                                    <span
                                      class="mgl10 tag"
                                      :class="{
                                        tag13: item.status == '加入项目',
                                        tag10: item.status == '简历推荐',
                                        tag15: item.status == '客户面试',
                                        tag12: item.status == 'Offer',
                                        tag11: item.status == '入职',
                                        tag09: item.status == '成功',
                                      }"
                                      >{{ item.status }}</span
                                    >
                                    <div class="edit" v-if="item.content">
                                      <span
                                        v-if="
                                          item.status != '重置' &&
                                          item.status != '成功'
                                        "
                                      >
                                        <span
                                          v-if="
                                            (item.createdUserId == useId ||
                                              projectManageId == useId) &&
                                            item.status != '撤销'
                                          "
                                          @click.stop="
                                            scope.row.hasPermission == null ||
                                            scope.row.hasPermission == false
                                              ? ''
                                              : editRecode(item)
                                          "
                                          class="ishanlder"
                                          style="margin-right: 15px"
                                          >编辑
                                        </span>
                                      </span>
                                    </div>
                                  </template>
                                  <div
                                    v-if="item.status == '简历推荐'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <p>
                                      推荐时间：{{
                                        JSON.parse(item.content).recommendTime
                                      }}
                                    </p>
                                    <div
                                      v-for="(fileInfo, index) in JSON.parse(
                                        item.content
                                      ).files"
                                      :key="index"
                                    >
                                      推荐报告：<span
                                        class="tabHref"
                                        @click="preOrdown(fileInfo)"
                                        >{{ fileInfo.fileName }}</span
                                      >
                                      <span
                                        class="mgl10 tabHref"
                                        style="color: #666"
                                        @click="fileDownload(fileInfo)"
                                        ><i class="el-icon-download"></i
                                      ></span>
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.status == '客户面试'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <p>
                                      面试时间：{{
                                        JSON.parse(
                                          item.content
                                        ).interviewDate.substring(0, 16)
                                      }}
                                    </p>
                                    <p>
                                      面试轮数：{{
                                        JSON.parse(item.content).interviewRound
                                      }}<span
                                        v-if="JSON.parse(item.content).isFinal"
                                        >(终面)</span
                                      >
                                      <span
                                        style="float: right"
                                        v-if="
                                          JSON.parse(item.content).addToSchedule
                                        "
                                        >已添加至日程</span
                                      >
                                    </p>
                                  </div>
                                  <div
                                    v-if="item.status == 'Offer'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <div
                                      style="
                                        border: 1px #ececec solid;
                                        margin-bottom: 10px;
                                      "
                                    >
                                      <p
                                        style="
                                          color: #333;
                                          font-size: 14px;
                                          background: #f1f1f1;
                                          padding: 0 5px;
                                        "
                                      >
                                        薪资结构
                                      </p>
                                      <div
                                        v-for="(i, index) in JSON.parse(
                                          item.content
                                        ).offerSalaries"
                                        :key="index"
                                        style="padding: 5px 10px"
                                      >
                                        <p>
                                          【{{ i.structure }}】{{ i.amount }}*{{
                                            i.count
                                          }}={{ i.totalAmount }}
                                        </p>
                                      </div>
                                    </div>
                                    <div style="border: 1px #ececec solid">
                                      <p
                                        style="
                                          color: #333;
                                          font-size: 14px;
                                          background: #f1f1f1;
                                          padding: 0 5px;
                                        "
                                      >
                                        喜报信息
                                      </p>
                                      <div style="padding: 5px 10px">
                                        <p>
                                          offer时间：{{
                                            JSON.parse(item.content).offerTime
                                          }}
                                        </p>
                                        <p>
                                          预到岗时间：{{
                                            JSON.parse(item.content)
                                              .planEntryTime
                                          }}
                                        </p>
                                        <p>
                                          offer金额：{{
                                            JSON.parse(item.content).offerMoney
                                          }}
                                        </p>
                                        <p>更多喜报信息请见【业绩池】</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.status == '背景调查'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <p>
                                      背景调查时间：{{
                                        JSON.parse(item.content).recommendTime
                                      }}
                                    </p>
                                    <div
                                      v-for="(fileInfo, index) in JSON.parse(
                                        item.content
                                      ).files"
                                      :key="index"
                                    >
                                      背调报告：
                                      <span
                                        class="tabHref"
                                        @click="preOrdown(fileInfo)"
                                        >{{ fileInfo.fileName }}</span
                                      >
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.status == '入职'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <p>
                                      入职时间：{{
                                        JSON.parse(item.content).entryTime
                                      }}
                                    </p>
                                    <p>
                                      试用期结束：{{
                                        JSON.parse(item.content).endTimeOfTrial
                                      }}
                                      <span
                                        style="float: right"
                                        v-if="
                                          JSON.parse(item.content).addToSchedule
                                        "
                                        >已添加至日程</span
                                      >
                                    </p>
                                    <p>
                                      约定首次开票时间：{{
                                        JSON.parse(item.content)
                                          .conventionFirstMakeInvoiceDate
                                      }}
                                      <span
                                        style="float: right"
                                        v-if="
                                          JSON.parse(item.content)
                                            .firstMakeInvoiceAddToSchedule
                                        "
                                        >已添加至日程</span
                                      >
                                    </p>
                                  </div>
                                  <div
                                    v-if="
                                      item.feedbackRecords &&
                                      item.feedbackRecords.length > 0
                                    "
                                  >
                                    <div class="feedbackItems">
                                      <div
                                        class="feedbackItem"
                                        v-for="(
                                          i, index
                                        ) in item.feedbackRecords"
                                        :key="index"
                                      >
                                        <p class="tit">
                                          <span class="tit_l"
                                            ><i class="iconfont icon-pl_fk"></i
                                            >反馈 【{{ i.feedback }}】 </span
                                          ><span>{{
                                            i.createdTime
                                              ? i.createdTime.substring(0, 16)
                                              : ""
                                          }}</span
                                          ><span class="mgl10">{{
                                            i.createdUserName
                                          }}</span>
                                        </p>
                                        <ul class="feedbackDes">
                                          <li
                                            v-for="(
                                              j, index
                                            ) in i.commentColumns"
                                            :key="'Col' + index"
                                          >
                                            <p class="titt">
                                              {{ index + 1 }}.{{
                                                j.columnTitle
                                              }}：
                                            </p>
                                            <p class="con">
                                              <span v-html="j.valueHtml"></span>
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </el-collapse-item>
                              </el-collapse>
                            </div>
                          </div>
                        </el-col>
                        <el-col
                          v-else
                          :span="24"
                          style="text-align: center; color: #909399"
                        >
                          <p>暂无操作记录！</p>
                        </el-col>
                      </el-row>
                      <div
                        slot="reference"
                        @mouseover="getOperaterecordPageList(scope.row)"
                        style="display: inline-block; cursor: pointer"
                      >
                        <span
                          class="tag"
                          :class="{
                            tag13: scope.row.status == '加入项目',
                            tag10: scope.row.status == '简历推荐',
                            tag15: scope.row.status == '客户面试',
                            tag12: scope.row.status == 'Offer',
                            tag11: scope.row.status == '入职',
                            tag09: scope.row.status == '成功',
                          }"
                          >{{ scope.row.status
                          }}<i
                            style="font-style: normal"
                            v-if="scope.row.status == '客户面试'"
                            >：{{ scope.row.interviewRemark }}</i
                          ></span
                        >
                      </div>
                    </el-popover>
                    <!-- 反馈 -->
                    <span
                      class="tag tag23 mgl10"
                      v-if="scope.row.status == '入职' && scope.row.entryStatus"
                      >{{ scope.row.entryStatus }}</span
                    >
                    <span
                      class="tag mgl10"
                      v-else-if="
                        scope.row.status == 'Offer' && scope.row.offerStatus
                      "
                      :class="{
                        tag23: scope.row.offerStatus == '拒绝',
                        tag11: scope.row.offerStatus == '接受',
                      }"
                      >{{ scope.row.offerStatus }}</span
                    >
                    <span
                      class="tag mgl10"
                      v-else-if="
                        scope.row.status == '简历推荐' &&
                        scope.row.recommendStatus
                      "
                      :class="{
                        tag23:
                          scope.row.recommendStatus == '客户淘汰' ||
                          scope.row.recommendStatus == '候选人拒绝',
                        tag24: scope.row.recommendStatus == '待定',
                      }"
                      >{{ scope.row.recommendStatus }}</span
                    >
                    <span
                      class="tag mgl10"
                      v-else-if="
                        scope.row.status == '客户面试' &&
                        scope.row.interviewStatus
                      "
                      :class="{
                        tag23:
                          scope.row.interviewStatus == '客户淘汰' ||
                          scope.row.interviewStatus == '候选人拒绝',
                        tag11: scope.row.interviewStatus == '通过',
                        tag24: scope.row.interviewStatus == '待定',
                      }"
                      >{{ scope.row.interviewStatus }}</span
                    >
                    <span v-else>
                      <span
                        class="tag mgl10 tag23"
                        v-if="scope.row.isFail && scope.row.failRemark"
                        >{{ scope.row.failRemark }}</span
                      >
                    </span>
                    <el-popover
                      placement="bottom"
                      width="350"
                      trigger="click"
                      :ref="`popover-${scope.$index}`"
                      popper-class="feedBackCss"
                      v-if="
                        scope.row.status !== '加入项目' &&
                        scope.row.status !== '成功' &&
                        !scope.row.goodNewsApproving &&
                        scope.row.hasPermission
                      "
                    >
                      <div class="feedBackTitle">反馈</div>
                      <div class="feedBackStyle">
                        <el-radio-group
                          v-model="feedBackStatus"
                          v-if="scope.row.status == '简历推荐'"
                        >
                          <el-radio label="待定">待定</el-radio>
                          <el-radio label="客户淘汰">客户淘汰</el-radio>
                          <el-radio label="候选人拒绝">候选人拒绝</el-radio>
                        </el-radio-group>
                        <el-radio-group
                          v-model="feedBackStatus"
                          v-if="scope.row.status == '客户面试'"
                        >
                          <el-radio label="待定">待定</el-radio>
                          <el-radio label="通过">通过</el-radio>
                          <el-radio label="客户淘汰">客户淘汰</el-radio>
                          <el-radio label="候选人拒绝">候选人拒绝</el-radio>
                        </el-radio-group>
                        <el-radio-group
                          v-model="feedBackStatus"
                          v-if="scope.row.status == 'Offer'"
                        >
                          <el-radio label="拒绝">拒绝</el-radio>
                          <el-radio label="接受">接受</el-radio>
                        </el-radio-group>
                        <el-radio-group
                          v-model="feedBackStatus"
                          v-if="scope.row.status == '入职'"
                        >
                          <el-radio label="候选人拒绝">候选人拒绝</el-radio>
                          <el-radio label="试用期离职">试用期离职</el-radio>
                          <el-radio label="未过试用期">未过试用期</el-radio>
                          <el-radio label="其他">其他</el-radio>
                        </el-radio-group>
                        <el-form
                          @submit.native.prevent
                          label-position="top"
                          v-if="
                            CommentWay.columns && CommentWay.columns.length > 0
                          "
                        >
                          <el-row :gutter="24">
                            <el-col
                              :span="24"
                              v-for="(item, index) in CommentWay.columns"
                              :key="index"
                            >
                              <el-form-item
                                :label="index + 1 + '.' + item.title"
                                :required="item.required"
                                class="selectLabel"
                              >
                                <div
                                  v-if="
                                    item.type == '单选型' &&
                                    item.selectType == '单选'
                                  "
                                >
                                  <el-radio-group v-model="item.answer">
                                    <p
                                      v-for="(i, _index) in item.options"
                                      :key="_index"
                                    >
                                      <el-radio
                                        :label="i"
                                        @click.native.prevent="
                                          clickItem(i, index)
                                        "
                                        >{{ i }}</el-radio
                                      >
                                    </p>
                                  </el-radio-group>
                                </div>
                                <div
                                  v-if="
                                    item.type == '单选型' &&
                                    item.selectType == '下拉'
                                  "
                                >
                                  <el-select
                                    v-model="item.answer"
                                    clearable
                                    placeholder="请选择"
                                  >
                                    <el-option
                                      v-for="(i, _index) in item.options"
                                      :key="_index"
                                      :label="i"
                                      :value="i"
                                    ></el-option>
                                  </el-select>
                                </div>
                                <div v-if="item.type == '多选型'">
                                  <el-checkbox-group v-model="item.answer1">
                                    <p
                                      v-for="(i, _index) in item.options"
                                      :key="_index"
                                    >
                                      <el-checkbox :label="i">{{
                                        i
                                      }}</el-checkbox>
                                    </p>
                                  </el-checkbox-group>
                                </div>
                                <div v-if="item.type == '文本描述型'">
                                  <el-input
                                    v-model="item.answer"
                                    placeholder="请输入内容"
                                    type="textarea"
                                    :rows="3"
                                  ></el-input>
                                </div>
                                <div v-if="item.type == '日期型'">
                                  <el-date-picker
                                    prefix-icon="el-icon-date"
                                    v-model="item.answer"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy/MM/dd"
                                  ></el-date-picker>
                                </div>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-form>
                      </div>
                      <div class="footer-btn">
                        <el-button
                          size="mini"
                          type="default"
                          @click="
                           closePop(scope.$index)
                          "
                          >取消</el-button
                        >
                        <el-button
                          type="primary"
                          size="mini"
                          @click="saveFeedBack(scope.row,scope.$index)"
                          >确定</el-button
                        >
                      </div>
                      <span
                        slot="reference"
                        @click="
                          scope.row.hasPermission == null ||
                          scope.row.hasPermission == false
                            ? ''
                            : feedBack(scope.row)
                        "
                        class="tag tag07 ishanlder"
                        style="margin-left: 8px; cursor: pointer"
                        >反馈</span
                      >
                    </el-popover>
                    <span
                      class="tag tag23 mgl10"
                      v-if="scope.row.goodNewsApproving"
                      >喜报审批中</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="100"
                  label="标识"
                  prop="flag"
                  align="left"
                  class-name="canEdit"
                >
                  <template slot="header">
                    标识
                    <div
                      class="sortL"
                      :class="{
                        ascing: sortType == 'flag' && sortCheck == 1,
                        descing: sortType == 'flag' && sortCheck == 2,
                      }"
                    >
                      <i class="asc" @click.stop="sortWay('asc', 'flag')"></i>
                      <i class="desc" @click.stop="sortWay('desc', 'flag')"></i>
                    </div>
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            multiple
                            collapse-tags
                            clearable
                            v-model="searchInfo.flags"
                            style="width: 100%"
                            @change="submitForm()"
                          >
                            <el-option
                              v-for="(item, index) in flagList"
                              :key="index"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.flag ? scope.row.flag : '灰旗'"
                      effect="dark"
                      placement="top"
                    >
                      <span>
                        <i
                          class="iconfont icon-Pl_bs"
                          :style="{
                            color:
                              scope.row.flag == '红旗'
                                ? '#d03a3a'
                                : scope.row.flag == '橙旗'
                                ? '#ff864a'
                                : scope.row.flag == '蓝旗'
                                ? '#44a9ff'
                                : scope.row.flag == '黑旗'
                                ? '#3a3a3a'
                                : '#a9a9a9',
                          }"
                        ></i
                      ></span>
                    </el-tooltip>
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      @show="updateFlag.flagType = scope.row.flag || '灰旗'"
                      trigger="click"
                      v-if="scope.row.hasPermission"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            filterable
                            v-model="updateFlag.flagType"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="(item, index) in flagList"
                              :key="index"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancelPop"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal(scope.row.id, '1', scope.$index)"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column prop min-width="80" label="备注" align="left">
                  <template slot-scope="scope">
                    <div
                      @click.stop="
                        openDialog(
                          ['其它', '候选人沟通'],
                          scope.row,
                          scope.$index
                        )
                      "
                    >
                      <div
                        v-if="isaddMarkResume"
                        @mouseover="showMark(scope.row, '人才')"
                        @mouseout="clearSelect()"
                        class="tabHref"
                      >
                        <i class="iconfont icon-pl_bz"></i>
                        <span>{{ scope.row.remark }}</span>
                      </div>
                      <div v-if="!isaddMarkResume">
                        <el-popover
                          placement="bottom-start"
                          width="450"
                          trigger="hover"
                          popper-class="popperH"
                          :open-delay="1000"
                          @show="showMark(scope.row, '人才')"
                          @hide="showMark(scope.row, '人才')"
                        >
                          <el-row :gutter="24">
                            <el-col :span="24" v-if="remarkDatas.length > 0">
                              <div class="statusTitle">
                                <el-collapse v-model="activeCollapse" accordion>
                                  <el-collapse-item
                                    :name="'item_' + index"
                                    v-for="(item, index) in remarkDatas"
                                    :key="index"
                                    class="foldStyle"
                                  >
                                    <template slot="title">
                                      <span
                                        class="tag"
                                        :class="{
                                          tag09: item.type == '候选人沟通',
                                          tag10: item.type == '其他',
                                          tag12: item.type == '简历推荐',
                                        }"
                                        >{{ item.commentName }}</span
                                      >
                                      <div class="edit">
                                        {{
                                          item.createdTime
                                            ? item.createdTime.substring(0, 16)
                                            : ""
                                        }}<span class="mgl10">{{
                                          item.creatorName
                                        }}</span>
                                      </div>
                                    </template>
                                    <el-form
                                      label-position="top"
                                      class="remaekRecode"
                                    >
                                      <el-form-item
                                        v-for="(
                                          i, index
                                        ) in item.commentColumns"
                                        :key="'item_' + index"
                                        :label="i.columnTitle + '：'"
                                      >
                                        {{ i.value }}
                                      </el-form-item>
                                    </el-form>
                                  </el-collapse-item>
                                </el-collapse>
                              </div>
                            </el-col>
                            <el-col
                              v-else
                              :span="24"
                              style="text-align: center; color: #909399"
                            >
                              <p>数据加载中…</p>
                            </el-col>
                          </el-row>
                          <div
                            slot="reference"
                            v-if="scope.row.remark"
                            class="tabHref"
                          >
                            <i class="iconfont icon-pl_bz"></i>
                            <span style="padding-left: 3px">{{
                              scope.row.remark
                            }}</span>
                          </div>
                        </el-popover>
                        <div class="tabHref" v-if="!scope.row.remark">
                          <i class="iconfont icon-pl_bz"></i>
                          {{ scope.row.remark }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="contactStatus"
                  min-width="100"
                  class-name="canEdit"
                >
                  <template slot="header">
                    状态
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            multiple
                            collapse-tags
                            clearable
                            v-model="searchInfo.contactStatus"
                            style="width: 100%"
                            @change="submitForm()"
                          >
                            <el-option
                              label="无状态"
                              value="无状态"
                            ></el-option>
                            <el-option
                              label="联系中"
                              value="联系中"
                            ></el-option>
                            <el-option label="推荐" value="推荐"></el-option>
                            <el-option label="面试" value="面试"></el-option>
                            <el-option label="待定" value="待定"></el-option>
                            <el-option
                              label="不合适"
                              value="不合适"
                            ></el-option>
                            <el-option
                              label="不考虑"
                              value="不考虑"
                            ></el-option>
                            <el-option
                              label="黑名单"
                              value="黑名单"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <span
                      class="tag"
                      :class="{
                        tag01: scope.row.contactStatus == '面试',
                        tag03: scope.row.contactStatus == '推荐',
                        tag05: scope.row.contactStatus == '联系中',
                        tag06: scope.row.contactStatus == '待定',
                        tag28: scope.row.contactStatus == '无状态',
                        tag29:
                          scope.row.contactStatus == '不考虑' ||
                          scope.row.contactStatus == '黑名单' ||
                          scope.row.contactStatus == '不合适',
                      }"
                      >{{ scope.row.contactStatus }}</span
                    >
                    <el-popover
                      v-if="scope.row.hasPermission"
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      @show="contactStatus = scope.row.contactStatus"
                      trigger="click"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            filterable
                            v-model="contactStatus"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="(item, index) in contactStatusList"
                              :key="index"
                              :value="item.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancelPop"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal(scope.row.id, '2', scope.$index)"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="mobile"
                  min-width="130"
                  class-name="canEdit"
                >
                  <template slot="header">
                    手机号
                  </template>
                  <template slot-scope="scope">
                    <span v-if="scope.row.offerOrEntryProjectResumeId"> *************</span>
                      <span v-else
                        >{{ scope.row.mobile?scope.row.mobile:'-' }}</span
                      >
                  </template>
                </el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop
                  min-width="200"
                  align="left"
                  color="#2b80ff"
                  class-name="canEdit"
                >
                  <template slot="header">
                    项目
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            size="mini"
                            v-model="searchInfo.projectName"
                          >
                            <el-button
                              slot="append"
                              icon="el-icon-search"
                              @click="submitForm()"
                            ></el-button>
                          </el-input>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    <router-link
                      class="tabHref"
                      target="_blank"
                      :to="{
                        path: '/project/details',
                        query: { id: scope.row.projectId },
                      }"
                      >{{ scope.row.projectName }}
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="currentCompany"
                  align="left"
                  min-width="200"
                  class-name="canEdit"
                >
                  <template slot="header">
                    目前公司
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input size="mini" v-model="searchInfo.company">
                            <el-button
                              slot="append"
                              icon="el-icon-search"
                              @click="submitForm()"
                            ></el-button>
                          </el-input>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.currentCompany ? scope.row.currentCompany : "-"
                    }}
                  </template>
                </el-table-column>

                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="currentPosition"
                  min-width="150"
                  align="left"
                  class-name="canEdit"
                >
                  <template slot="header">
                    目前职位
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input size="mini" v-model="searchInfo.title">
                            <el-button
                              slot="append"
                              icon="el-icon-search"
                              @click="submitForm()"
                            ></el-button>
                          </el-input>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                  <template slot-scope="scope">
                    {{
                      scope.row.currentPosition
                        ? scope.row.currentPosition
                        : "-"
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="creatorName"
                  align="left"
                  min-width="120"
                  class-name="canEdit"
                >
                  <template slot="header">
                    加入顾问
                    <el-popover
                      title="搜索"
                      popper-class="popperEdit"
                      placement="bottom"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            v-model="searchInfo.createdUserIds"
                            filterable
                            clearable
                            multiple
                            collapse-tags
                            @change="submitForm()"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="(i, index) in searchUserList"
                              :key="index"
                              :label="i.name"
                              :value="i.id"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>

                <el-table-column prop="age" label="年龄" min-width="80">
                  <template slot-scope="scope">
                    {{ scope.row.age ? scope.row.age : "-" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="nowLocation"
                  label="现居住地"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    {{ scope.row.nowLocation ? scope.row.nowLocation : "-" }}
                  </template>
                </el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="invoiceCount"
                  label="开票次数"
                  align="left"
                  min-width="80"
                >
                  <template slot-scope="scope">
                    <span
                      :class="scope.row.invoiceCount ? 'tabHref' : ''"
                      @click="scope.row.invoiceCount ? toRecode(scope.row) : ''"
                      >{{ scope.row.invoiceCount }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createdTime"
                  align="left"
                  min-width="160"
                  class-name="canEdit"
                >
                  <template slot="header">
                    加入项目时间
                    <div
                      class="sortL"
                      :class="{
                        ascing: sortType == 'createdTime' && sortCheck == 1,
                        descing: sortType == 'createdTime' && sortCheck == 2,
                      }"
                    >
                      <i
                        class="asc"
                        @click.stop="sortWay('asc', 'createdTime')"
                      ></i>
                      <i
                        class="desc"
                        @click.stop="sortWay('desc', 'createdTime')"
                      ></i>
                    </div>
                    <el-popover
                      placement="bottom"
                      title="搜索"
                      popper-class="popperEdit"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-date-picker
                            v-model="rangTime"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="submitForm()"
                          >
                          </el-date-picker>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="lastModifiedTime"
                  align="left"
                  min-width="160"
                  class-name="canEdit"
                >
                  <template slot="header">
                    最近更新时间
                    <div
                      class="sortL"
                      :class="{
                        ascing:
                          sortType == 'lastModifiedTime' && sortCheck == 1,
                        descing:
                          sortType == 'lastModifiedTime' && sortCheck == 2,
                      }"
                    >
                      <i
                        class="asc"
                        @click.stop="sortWay('asc', 'lastModifiedTime')"
                      ></i>
                      <i
                        class="desc"
                        @click.stop="sortWay('desc', 'lastModifiedTime')"
                      ></i>
                    </div>
                    <el-popover
                      placement="bottom"
                      title="搜索"
                      popper-class="popperEdit"
                      trigger="click"
                    >
                      <i
                        slot="reference"
                        class="heardSearch el-icon-search"
                      ></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-date-picker
                            v-model="ranglastModifiedTime"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="submitForm()"
                          >
                          </el-date-picker>
                        </div>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :hide-on-single-page="searchInfo.page.total > 10 ? false : true"
                v-if="searchInfo.page.total > 0 && !listLoading"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchInfo.page.current"
                :page-sizes="[10, 20, 50, 100]"
                :pager-count="5"
                :page-size="searchInfo.page.size"
                layout="total,prev, pager, next,sizes"
                :total="searchInfo.page.total"
                style="margin-bottom: 10px"
              ></el-pagination>
            </el-col>
          </el-row>
        </div>
      </section>
    </el-row>
    <el-dialog
      :visible.sync="subscribeSearchDialog"
      :modal-append-to-body="false"
      append-to-body
      title="订阅搜索"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        ref="subscribeForm"
        :model="subscribeForm"
        :rules="subscribeRules"
      >
        <el-form-item prop="name">
          <el-input
            v-model.trim="subscribeForm.name"
            maxlength="12"
            placeholder="请给订阅搜索取个名字"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="subscribeSearchDialog = false"
          >取消</el-button
        >
        <el-button
          size="mini"
          :loading="isSubscribeSearch"
          @click="saveSubscribe('subscribeForm')"
          type="primary"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--    文件夹管理-->
    <el-dialog
      :visible.sync="fileManagement"
      :modal-append-to-body="false"
      append-to-body
      title="文件夹管理"
      :close-on-click-modal="false"
      width="720px"
    >
      <file
        v-if="fileManagement"
        @getFavoriteData="childByValue"
        @length="getLength"
        :type="type"
        :checkbox="isCheckbox"
      ></file>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="isCheckbox.edit && isShowSaveBtn"
      >
        <el-button
          size="mini"
          :loading="saveloading"
          @click="saveFavorite"
          type="primary"
          :disabled="favoriteList.favoriteIds.length == 0"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="加入项目"
      :visible.sync="dialogaddProgreamVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="80%"
    >
      <progream
        v-if="dialogaddProgreamVisible"
        :resumeIds="this.resumeIds"
        :source="source"
        entry="团队Pipeline"
        :hookedLabelIds="searchInfo.tagIds"
        @loadList="
          loadList();
          dialogaddProgreamVisible = false;
        "
      ></progream>
    </el-dialog>
    <!-- 人选推荐 -->
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :width="ishappyNews ? '70%' : '600px'"
      :before-close="handleClose"
      class="customerDialog"
    >
      <!-- 背景调查 -->
      <backgroundSurvey
        v-if="isBackgroundSurvey"
        ref="saveBackgroundSurvey"
        :projectResumeId="projectResumeId3"
        @changeSaveLoading="changeSaveLoading"
        @loadList="loadList()"
      ></backgroundSurvey>
      <!-- 入职 -->
      <enrty
        v-if="isEnrty"
        ref="saveEntry"
        :projectResumeId="projectResumeId3"
        @loadList="loadList()"
      ></enrty>
      <!-- 写报告 -->
      <div v-if="iswriteReport">
        <el-table
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          :data="optionsReport"
          style="width: 100%"
        >
          <el-table-column prop="type" label="模板文件类型"> </el-table-column>
          <el-table-column prop="nameWithExtension" label="报告名称">
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <span class="tabHref" @click="downLoadReport(scope.row.id)"
                >下载报告</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 重置 -->
      <el-form
        v-if="isReset"
        style="padding: 0 50px"
        :model="resetRuleForm"
        :rules="resetRules"
        ref="resetRuleForm"
      >
        <el-form-item prop="resetStatus">
          <el-select
            style="width: 100%"
            v-model="resetRuleForm.resetStatus"
            placeholder="请选择阶段"
          >
            <el-option
              v-for="(item, index) in resetList"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" v-if="!iswriteReport" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisible = false), cancel()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveInfos"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 创建财务任务 -->
    <el-drawer
      :visible.sync="isfinancialTask"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <div v-if="isfinancial">
          <i class="drawIcon iconfont icon-pl_cjcw"></i>创建财务任务({{
            financialTaskName
          }})
        </div>
        <div v-if="ishappyNews||isWhiteCollarWorker">
          <i class="drawIcon iconfont icon-pl_xb"></i>offer
        </div>
      </div>
      <!-- 发喜报-->
      <div class="drawerCon">
        <happyNews
          v-if="ishappyNews"
          ref="saveHappyNews"
          :projectResumeId="currentOperaterecord.id"
          @changeSaveLoading="changeSaveLoading"
          @loadList="loadList()"
        ></happyNews>
        <whiteCollarWorker ref="whiteCollarWorker" v-if="isWhiteCollarWorker"  @loadList="loadList()" :isAnnouncement='isAnnouncement' :projectResumeId="currentOperaterecord.id" @changeSaveLoading="changeSaveLoading"></whiteCollarWorker>
        <!-- 创建财务任务 -->
        <financialTasks
          v-if="isfinancial"
          ref="saveFinancialTask"
          :projectResumeId="currentOperaterecord.id"
          :companyId="currentOperaterecord.companyId"
          @changeSaveLoading="changeSaveLoading"
          @loadList="cancel()"
        ></financialTasks>
        <div v-if="!iswriteReport" class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="(isfinancialTask = false), cancel()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
            style="margin-right: 15px"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 候选人详情 -->
    <el-dialog
      :visible.sync="mouseoverDialog"
      append-to-body
      :close-on-click-modal="true"
      :modal="false"
      custom-class="floatwindow"
      class="dialogFloatwindow"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>人才
      </div>

      <resumeDetails
        v-if="mouseoverDialog"
        :hover="hoverInfo"
        :resumeDetailId="resumeId"
        :repeatResume="repeatResume"
      ></resumeDetails>
    </el-dialog>
    <!-- 标签管理 -->
    <el-dialog
      title="标签管理"
      :visible.sync="dialogVisibleOfTag"
      append-to-body
      :close-on-click-modal="false"
      width="960px"
      custom-class="addTagDig tagsDigContent"
    >
      <label-management
        ref="labelManagement"
        v-if="dialogVisibleOfTag"
        :resumeId="favoriteList.itemIds"
        entry="团队pipeline-人才列表"
        @loadList="(dialogVisibleOfTag = false), loadList()"
      ></label-management>

      <span slot="footer" class="dialog-footer" v-if="dialogVisibleOfTag">
        <el-button
          type="default"
          plain
          size="mini"
          @click="dialogVisibleOfTag = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveTags"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 导出数据 -->
    <el-drawer
      :visible.sync="exportOptionsDialog"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">导出表格</div>

      <exportExcelDialog
        v-show="exportOptionsDialog"
        :searchList="searchInfo"
        @showSide="showSide"
      ></exportExcelDialog>
    </el-drawer>
    <!-- 备注 -->
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisibleSingle"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
      <!-- 备注 -->
      <addMarkReacode
        v-if="isaddMark"
        :customId="currentOperaterecord.id"
        :resumeId="currentOperaterecord.resumeId"
        :fromPipe="true"
        relativeType="项目候选人"
        :type="isInnerInterview ? typeContent : currentOperaterecord.status"
        @changeSaveLoading="changeSaveLoading"
        @loadRecodeList="loadList()"
        ref="saveMark"
      ></addMarkReacode>
      <addMarkReacode
        v-if="isaddMarkResume"
        :customId="currentOperaterecord.id"
        relativeType="项目候选人"
        type="候选人沟通"
        :fromPipe="true"
        @changeSaveLoading="changeSaveLoading"
        @loadRecodeList="setTableData"
        @Invalidate="Invalidate"
        ref="saveMarkResume"
      ></addMarkReacode>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisibleSingle = false), cancel()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveInfos"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--    人选类型非候选人-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleType"
      width="600px"
      :before-close="handleClose"
    >
      <p>
        当前备注人选为cold call ，是否补充为候选人？（cold call
        的备注不计入KPI）
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="goRemark"
          >直接备注</el-button
        >
        <el-button type="primary" size="mini" @click="perfectFoo"
          >完善人才</el-button
        >
      </span>
    </el-dialog>
    <!--编辑人才-->
    <el-drawer
      title="编辑"
      :visible.sync="drawerdialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      :before-close="handleClose"
    >
      <div class="drawerCon">
        <!-- 修改信息 -->
        <resumeInfo
          v-if="drawerdialogVisible"
          :isPipe="true"
          :resumeId="resumeId"
          @loadPipeList="loadList()"
          ref="saveCusInfo"
        ></resumeInfo>
        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="(drawerdialogVisible = false), cancel()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="isDrawerVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      destroy-on-close
      :before-close="emailDrawerClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-pl_cjcw"></i>{{ titName }}
      </div>
      <div class="drawerCon">
        <div style="padding: 15px">
          <!-- offer -->
          <offer
            v-if="isOffer"
            ref="saveOffer"
            :projectResumeId="projectResumeId3"
            @loadList="loadList()"
          ></offer>
          <!-- 面试 -->
          <interview
            v-if="isInterview"
            ref="saveInterview"
            :resumeMemberId="resumeMemberId"
            :projectResumeId="projectResumeId3"
            :projectId="projectId1"
            :resumeId="resumeId"
            :iseditD="iseditD"
            @loadList="loadList()"
            @emailDrawerClose="emailDrawerClose"
          ></interview>
          <!--        简历推荐-->
          <recommend
            v-if="isRecommend"
            ref="saveResumeRecommendation"
            :projectResumeId="projectResumeId3"
            :projectId="projectId1"
            :iseditD="iseditD"
            :resumeId="resumeId"
            @loadList="loadList()"
            @emailDrawerClose="emailDrawerClose"
          ></recommend>
        </div>

        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="(isDrawerVisible = false), cancel()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 15px"
            :loading="saveloading"
            @click="saveInfos"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 写邮件 -->
    <el-drawer
      :visible.sync="dialogVisibleOfEmail"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-pl_cjcw"></i>写邮件
      </div>
      <div class="drawerCon">
        <sendEmailFrom
          v-if="dialogVisibleOfEmail"
          ref="sendEmailFrom1"
          type="批量发邮件"
          :candidateIds="resumeIds"
          @loadList="changeLoading"
        ></sendEmailFrom>
        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="dialogVisibleOfEmail = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveEmail"
            style="margin-right: 15px"
            >发送</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="isShowDetail"
      append-to-body
      :modal="false"
      custom-class="drawerClass redrawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div
        slot="title"
        class="drawerTitle"
        style="display: flex; justify-content: space-between"
      >
        <p><i class="drawIcon iconfont icon-rc_bt"></i>人才</p>
        <div>
          <el-button size="mini" @click="goNewPage(resumeId)">
            新窗口打开
          </el-button>
          <el-button size="mini" @click="showleftContent(resumeId)">
            {{ this.isshowleftContent ? "隐藏" : "显示" }}侧边栏
          </el-button>
        </div>
      </div>
      <div class="l_box" style="display: flex">
        <div class="l_content" v-if="isshowleftContent">
          <p class="l_c_tit">姓名</p>
          <div class="l_c_list">
            <p
              class="tabHref"
              v-for="(i, idx) in ListData1"
              :key="String(idx) + i.id"
              @click="updata(i.resumeId)"
              :class="i.isChoice ? 'l_c_list_active' : ''"
            >
              {{ i.userName }}
            </p>
          </div>
        </div>
        <resumeDetails
          ref="resumeDetails"
          :style="
            isshowleftContent ? 'width: calc(100% - 210px)' : 'width:100%'
          "
          style="height: calc(100vh - 120px); overflow: auto"
          v-if="isShowDetail"
          :resumeDetailId="resumeId"
          :hover="hoverInfo1"
          :repeatResume="repeatResume"
          @getCallMobileInfo="getCallMobileInfo"
        ></resumeDetails>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getOperaterecordPageList,
  getProjectresumePageList,
  addToFavorite,
  getUserBaseInfos, //获取用户列表
  getLabelOptions, //获取标签列表
  getCommonColumnList, //获取行业 城市列表
  getCommonRecordList,
  SearchAddOrEdit,
  GetSearchPage,
  updateFlagType, //更新标识
  deleteSearchById,
  postContactStatus, //联系人状态更新
  statusInfoWithHistory,
  removeProject, // 候选人移出项目
  GetSearchById, //获取订阅搜索
  getCommonTemplateReportList, //获取报告
  reportWrite,
  cancelOperate, //撤销
  getResetStatus, //获取重置状态
  latestComment, //获取最新备注
  recommendStatus,
  interviewStatus,
  offerStatus,
  entryStatus,
  addReset,
  canCreateFinanceTask,
  fileDownload,
  canOperate,
  offerGoodnewsDefaultData, //Offer数据
  haveSend, //是否已经发送过喜报
  cityTree, //城市树
  getLabelCategoryOptions, //获取所有标签类别
  setTopProjectResume, //置顶人才
  cancelTopProjectResume, //取消置顶人才
  permissionTree, //获取用户的组织架构
} from "../../api/api";
import {
  statisticsResumeNewWindow, //记录人才详情，新窗口打开点击量
  statisticsResumeAddProject, //记录加入项目点击量
  statisticsClickFunctionEscape, //记录功能或页面跳出
} from "../../api/buryingPoint";
import { mapGetters } from "vuex";
import progream from "../../components/progream";
import CityChoose from "../../components/cityChoose";
import resumeDetails from "../../components/resumeDetail";
import resumeInfo from "../../components/resumeInfo";
import exportExcelDialog from "../../components/exportExcel";
import recommend from "../../components/recommend";
import interview from "../../components/interview";
import sendEmailFrom from "../../components/pipeline/sendEmailFrom";
import offer from "../../components/offer";
import addMarkReacode from "../../components/remark/remark";
import financialTasks from "../../components/pipeline/financialTasks";
import happyNews from "../../components/happyNews";
import whiteCollarWorker from "../../components/whiteCollarWorker";
import backgroundSurvey from "../../components/backgroundSurvey";
import enrty from "../../components/enrty";
import file from "../../components/file/file";
import * as data from "./../../libs/data.json";
import { ApiBaseUrl, PreviewUrl } from "../../api/http";
import labelManagement from "../../components/resume/labelManagement";
export default {
  props: ["projectId", "resumeNo", "proStatus", "source", "projectName"],
  components: {
    resumeDetails,
    progream,
    resumeInfo,
    exportExcelDialog,
    CityChoose,
    addMarkReacode,
    recommend,
    interview,
    offer,
    happyNews,
    backgroundSurvey,
    enrty,
    file,
    financialTasks,
    sendEmailFrom,
    labelManagement, //标签管理
    whiteCollarWorker
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
    activeCollapse() {
      return this.remarkDatas.map((i, index) => {
        return "item_" + index;
      });
    },
  },
  data() {
    const intNum = (rule, value, callback) => {
      // 校验数字
      let reg = /^[0-9]*$/;
      if (value && !reg.test(value)) {
        callback(new Error("请输入数字"));
      } else {
        callback();
      }
    };
    return {
      screenLoading: true,
      projectHasPre: false,
      hasDownExport: false,
      hasDownExportAll: false,
      resetRuleForm: { resetStatus: "" },
      resetRules: {
        resetStatus: [{ required: true, message: "请输入状态" }],
      },
      optionsReport: [],
      resetList: [],
      dialogVisibleType: false,
      drawerdialogVisible: false, //完善人才
      rangTime: [], //加入项目时间区间、
      ranglastModifiedTime: [], //最近更新时间
      typeContent: "内部面试",
      isaddMark: false,
      quickValue: [],
      operationLogList: [],
      currentItem: null,
      statusType: "",
      list: [],
      flagList: [
        { color: "#d03a3a", value: "红旗" },
        { color: "#ff864a", value: "橙旗" },
        { color: "#44a9ff", value: "蓝旗" },
        { color: "#a9a9a9", value: "灰旗" },
        { color: "#3a3a3a", value: "黑旗" },
      ],
      contactStatusList: [
        { value: 1, label: "联系中" },
        { value: 2, label: "推荐" },
        { value: 3, label: "面试" },
        { value: 4, label: "待定" },
        { value: 5, label: "无状态" },
        { value: 6, label: "不考虑" },
        { value: 7, label: "不合适" },
        { value: 8, label: "黑名单" },
      ],
      menuList: [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
        {
          name: "团队Pipeline",
          val: "团队Pipeline",
        },
      ],
      actived: "团队Pipeline",
      feedBackStatus: null,
      CommentWay: "", //反馈对应备注方案
      mouseoverDialog: false,
      options: data.options,
      useId: parseInt(this.getStore("uid")),
      timer: null,
      time: false,
      updateFlag: { projectResumeId: "", flagType: "灰旗" },
      contactStatus: [],
      resumeId: "",
      createTag: {
        resumeIds: "",
        labelIds: [],
      },
      searchTag: "",
      listLoading: false,
      saveloading: false,
      isShowSaveBtn: false,
      multipleSelection: [],
      remarkList: [],
      dialogVisibleOfTag: false,
      dialogVisibleOfEmail: false,
      newTagList: [],
      newTagSerchList: [],
      addTypeList: [],
      exportOptionsDialog: false,
      sortCheck: null,
      sortType: "",
      dialogVisible: false,
      titName: "",
      width: "75%",
      isRecommend: false,
      isInterview: false,
      isOffer: false,
      isBackgroundSurvey: false,
      isEnrty: false,
      ishappyNews: false,
      isWhiteCollarWorker:false,
      isfinancialTask: false,
      isfinancial: false,
      iswriteReport: false,
      isReset: false,
      isaddMarkResume: false,
      dialogVisibleSingle: false,
      currentOperaterecord: null,
      isValidate: false,
      cancelOperateId: "",
      cancelOperateStatus: "",
      projectManageId: null,

      dialogaddProgreamVisible: false,
      resumeIds: [], //人才id集合
      subscribeSearchDialog: false,
      type: "人才",
      fileManagement: false,
      isCheckbox: {
        val: true,
        edit: false,
      },
      subscribeRules: {
        name: [
          { required: true, trigger: "blur", message: "请给订阅搜索取个名字" },
        ],
      },
      subscribeForm: {
        name: "",
        searchList: "",
        type: "团队Pipeline",
        version: "",
      },
      activeNames: ["1", "2"],
      OrderSearchList: [],
      remarkDatas: [],
      searchUserList: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      cityProps: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: true,
      },
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: false },
      hoverInfo1: { matchAll: true, majorSearch: false, hover: true },
      searchInfo: {
        channels: [], //渠道
        checkSchoolType: false, //是否按学校类型搜索
        company: "", //公司名称
        contact: "", //联系方式
        projectStatus: ["进行中"], //项目状态
        contactStatus: [],
        createEndTime: "",
        createStartTime: "",
        educations: ["UnLimit"], //教育经历 [UnLimit,本科及以上, 硕士及以上, 博士及以上, 大专及以上, 高中及以下]
        firstDegrees: [], //第一学历
        flags: [],
        createdUserIds: [],
        forwardLocations: [], //期望城市
        forwardTitle: "", //期望职位
        forwardVocation: "", //期望职能
        forwardVocations: [], //期望行业
        functionIds: [], //职能
        highAimSalary: "", //期望最多月薪
        id: "", //简历编号
        isAllCompany: false, //是否全部公司
        isAllTitle: false, //是否全部职位
        is_tongzhao: false, //是否统招
        jobStatus: [], //求职状态
        lowAimSalary: "", //期望最少月薪
        married: [], //婚姻状况
        maxAge: "", //最大年龄
        minAge: "", //最小年龄
        nowLocations: [], //现居住城市
        ownerUserIds: [], //人选拥有者
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "lastModifiedTime",
            },
          ],
          current: 1,
          size: 20,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
        projectName: "", //项目名称
        projectId: "",
        quickType: "团队Pipeline",
        resumeType: "", //候选人类型：1-候选人，2-code call
        sex: "", //性别
        status: null,
        searchKey: "",
        tagIds: [],
        title: "", //职位名称
        userName: "", //姓名
        vocationIds: [], //行业Id
        workExperiences: ["UnLimit"], //工作年限 [UnLimit, OneToThree, ThreeToFive, FiveToTen, TenMore]
        showDisabledUser: false,
        consultantIds: [],
        statusList:[]
      },
      ListData: [],
      ListData1: [],
      isMoreItemShow: false,
      isMoreCity: false,
      isShowCompany: false,
      cascaderCityKey: 0,
      isResouceShow: 0,
      rulesOfSearch: {
        id: [{ validator: intNum, trigger: "blur" }],
        minAge: [{ validator: intNum, trigger: "blur" }],
        maxAge: [{ validator: intNum, trigger: "blur" }],
        lowAimSalary: [{ validator: intNum, trigger: "blur" }],
        highAimSalary: [{ validator: intNum, trigger: "blur" }],
      },
      isShowTitle: false,
      userList: [], //用户列表
      TagList: [], //标签列表
      ColumnCityList: [], // 城市列表
      ColumnInList: [], //行业
      ColumnPosList: [], //职能
      channelList: data.selectChannelList,
      timeVal: "",
      TimeJoinVal:[],
timeRecommendVal:[],
timeFirstInterviewVal:[],
timeOfferVal:[],
timeEntryVal:[],
      delFiles: {
        favoriteId: "",
        itemIds: [],
      },
      favoriteList: {
        favoriteIds: [],
        itemIds: [],
      },
      financialTaskName: "",
      isInnerInterview: false,
      handleOpts: [],
      isDrawerVisible: false,
      projectId1: "",
      iseditD: false,
      isSubscribeSearch: false,
      isShowDetail: false,
      isshowleftContent: false,
      choiceTypeName: "",
      tablechioceIndex: null,
      searchHoverContentIsShow: false,
      serchChoiceList: [],
      options2: [], //用户组织架构
      props1: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: false,
      },
      keyOfCreateUserIds: 0,
      userIds: [],
      statusListOption:['加入项目','简历推荐','客户面试','Offer','入职','成功'],
      isAnnouncement:false
    };
  },
  methods: {
    toRecode(data) {
      //开票次数跳转
      this.$router.push({
        path: "/financeEnquiries",
        query: {
          userName: data.userName,
        },
      });
    },
    preOrdown(item) {
      //预览或者下载
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xls" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            PreviewUrl +
            item.fileUrl,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + PreviewUrl + item.fileUrl,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(ApiBaseUrl + item.fileUrl, "_blank");
      } else if (fileType) {
        //下载
        this.fileDownload(item);
      }
    },
    fileDownload(file) {
      fileDownload({ fileName: file.fileName, filePath: file.fileUrl }).then(
        (res) => {
          if (!res) {
            this.$message.error("下载内容为空");
            return;
          }
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", file.fileName);

          document.body.appendChild(link);
          link.click();
          //释放URL对象所占资源
          window.URL.revokeObjectURL(url);
          //用完即删
          document.body.removeChild(link);
        }
      );
    },
    // 直接备注
    goRemark() {
      if (this.textFoo == "添加备注") {
        this.dialogVisibleSingle = true;
        this.isaddMark = true;
      } else if (this.textFoo == "内部面试") {
        this.dialogVisible = false;
        this.isInnerInterview = true;
        this.dialogVisibleSingle = true;
        this.isaddMark = true;
      } else if (this.textFoo == "编辑备注") {
        this.isInnerInterview = true;
        this.isaddMark = true;
        this.typeContent = "F2F面试";
        this.dialogVisible = false;
        this.dialogVisibleSingle = true;
      }

      this.dialogVisibleType = false;
    },
    perfectFoo() {
      this.dialogVisibleType = false;
      this.drawerdialogVisible = true;
    },
    Invalidate(val) {
      this.isValidate = val;
    },
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    showMark(row, type) {
      let _this = this;
      _this.time = false;
      _this.remarkDatas = [];
      this.timer = setTimeout(function () {
        _this.loadRecodeList(row, type);
      }, 1000);
    },
    loadRecodeList(row, type) {
      //加载备注记录List
      this.dialogVisible = false;
      this.remarkDatas = [];
      getCommonRecordList({
        relativeId: row.resumeId,
        type: type,
      }).then((res) => {
        if (res.success) {
          this.remarkDatas = res.result;
        }
      });
    },
    downLoadReport(id) {
      reportWrite({
        projectResumeId: this.currentOperaterecord.id,
        templateId: id,
      }).then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8",
        }); //application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型
        var contentDisposition = res.headers["content-disposition"]; //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        var patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
        var result = patt.exec(contentDisposition);
        var filename = result[1];
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); //创建下载的链接
        var reg = /^["](.*)["]$/g;
        downloadElement.style.display = "none";
        downloadElement.href = href;
        downloadElement.download = decodeURI(filename.replace(reg, "$1")); //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      });
    },
    saveInfos() {
      this.saveloading = true;
      // 人选推荐
      if (this.isRecommend) {
        this.$refs.saveResumeRecommendation
          .saveResumeRecommendation("resumeRecommendationForm")
          .then(() => {
            this.saveloading = false;
            this.iseditD = false;
            this.isDrawerVisible = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      // 客户面试
      if (this.isInterview) {
        this.$refs.saveInterview
          .saveInterview("interviewForm")
          .then(() => {
            this.saveloading = false;
            this.iseditD = false;
            this.isDrawerVisible = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
        this.dialogVisibleType = false;
      }
      if (this.isOffer) {
        this.$refs.saveOffer
          .saveOffer("offerForm")
          .then(() => {
            this.saveloading = false;
            this.isDrawerVisible = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      if (this.isBackgroundSurvey) {
        this.$refs.saveBackgroundSurvey
          .saveBackgroundSurvey("backgroundSurveyForm")
          .then(() => {
            this.saveloading = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      //保存人才信息 调用子组件方法
      if (this.drawerdialogVisible) {
        this.$refs.saveCusInfo
          .saveResume()
          .then((value) => {
            this.saveloading = false;
            this.drawerdialogVisible = false;
            this.loadList();
          })
          .catch(() => {
            this.saveloading = false;
          });
        this.dialogVisibleColdCall = false;
      }
      if (this.isEnrty) {
        this.$refs.saveEntry
          .saveEntry("enrtyForm")
          .then(() => {
            this.saveloading = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      if (this.isaddMark) {
        this.$refs.saveMark
          .saveMarkRecode()
          .finally(() => {
            this.saveloading = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
        this.dialogVisibleType = false;
      }
      if (this.isaddMarkResume) {
        //保存备注
        this.$refs.saveMarkResume
          .saveMarkRecode()
          .finally(() => {
            this.saveloading = false;
            if (this.isValidate) {
              this.isaddMarkResume = true;
            } else {
              this.isaddMarkResume = false;
            }
          })
          .catch(() => {
            this.saveloading = false;
          });
        this.dialogVisibleType = false;
      }
      if (this.ishappyNews) {
        this.$refs.saveHappyNews
          .saveHappyNews()
          .then(() => {
            this.saveloading = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      if (this.isWhiteCollarWorker) {
        this.$refs.whiteCollarWorker
          .saveForm('whiteCollarWorkerForm')
          .then(() => {
            this.saveloading = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      if (this.isfinancial) {
        this.$refs.saveFinancialTask
          .saveFinancialTask()
          .then(() => {
            this.saveloading = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      if (this.isReset) {
        this.$refs.resetRuleForm.validate((valid) => {
          if (valid) {
            addReset({
              id: 0,
              objectContent: {
                status: this.resetRuleForm.resetStatus,
              },
              projectResumeId: this.currentOperaterecord.id,
              version: 0,
            }).then((res) => {
              if (res.success) {
                this.$message.success("重置成功");
                this.saveloading = false;
                this.cancel();
                this.dialogVisible = false;
                this.dialogVisibleSingle = false;
                this.loadList();
              }
            });
          } else {
            this.saveloading = false;
            this.$message("请输入状态");
          }
        });
      }
    },
    sortWay(name, type) {
      //升降序
      this.sortType = type;
      this.sortCheck = null;
      this.searchInfo.page.orders[0].column = type;
      if (name == "asc") {
        this.sortCheck = this.sortCheck == 1 ? null : 1;
        this.searchInfo.page.orders[0].asc = true;
      }
      if (name == "desc") {
        this.sortCheck = this.sortCheck == 2 ? null : 2;
        this.searchInfo.page.orders[0].asc = false;
      }
      if (this.sortCheck == null) {
        this.searchInfo.page.orders[0].asc = "";
        this.searchInfo.page.orders[0].asc = "";
        this.searchInfo.page.orders[0].column = "";
      }
      this.loadList();
    },
    exportDown() {
      //导出
      this.exportOptionsDialog = true;
    },
    showSide() {
      this.exportOptionsDialog = !this.exportOptionsDialog;
    },
    checkVal(id, type, index) {
      if (type == "2") {
        //更新标识
        postContactStatus({
          contactStatus: this.contactStatus,
          id: id,
        }).then((res) => {
          if (res.success) {
            this.ListData[index].contactStatus = this.contactStatus;
            this.cancelPop();
          }
        });
      } else {
        this.updateFlag.projectResumeId = id;
        this.updateFlagType(index);
      }
    },
    handleSelectionChange(val) {
      //表格选择
      this.multipleSelection = val;
      this.favoriteList.itemIds = val.map((i) => i.resumeId);
    },
    cancelPop() {
      document.body.click();
    },
    handleClose(done) {
      done();
      this.cancel();
    },
    selectStyle(row) {
      let _this = this;
      if (_this.mouseoverDialog) {
        _this.mouseoverDialog = false;
      }
      _this.time = false;
      this.timer = setTimeout(function () {
        _this.resumeId = row.resumeId;
        if (!_this.time) {
          _this.mouseoverDialog = true;
        }
      }, 2000);
    },
    clearSelect() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    updateFlagType(index) {
      updateFlagType(this.updateFlag).then((res) => {
        if (res.success) {
          this.ListData[index].flag = this.updateFlag.flagType;
          this.cancelPop();
        }
      });
    },
    // 反馈
    feedBack(row) {
      this.currentOperaterecord = row;
      if (this.currentOperaterecord.status == "简历推荐") {
        this.feedBackStatus = this.currentOperaterecord.recommendStatus;
      } else if (this.currentOperaterecord.status == "客户面试") {
        this.feedBackStatus = this.currentOperaterecord.interviewStatus;
      } else if (this.currentOperaterecord.status == "Offer") {
        this.feedBackStatus = this.currentOperaterecord.offerStatus;
      } else if (this.currentOperaterecord.status == "入职") {
        this.feedBackStatus = this.currentOperaterecord.entryStatus;
      }
      latestComment({ type: this.currentOperaterecord.status }).then((res) => {
        if (res.success) {
          this.CommentWay = res.result;
          this.CommentWay.columns.forEach((item) => {
            this.$set(item, "answer1", []);
            this.$set(item, "answer", "");
          });
        }
      });
    },
    saveFeedBack(row,index) {
      if (!this.feedBackStatus) {
        this.$message.error("请选择反馈状态");
        return;
      }
      let commentRecord = {
        commentColumns: [],
        commentId: this.CommentWay.id,
        id: 0,
        type: this.CommentWay.type,
        version: 0,
      };
      let columns = this.CommentWay.columns;
      for (let i = 0; i < columns.length; i++) {
        if (
          columns[i].required &&
          (columns[i].answer == "" || columns[i].answer == null) &&
          columns[i].answer1.length == 0
        ) {
          this.$message.error("请将必填项填写完整");
          return false;
        }
      }
      columns.map((item) => {
        commentRecord.commentColumns.push({
          columnTitle: item.title,
          commentColumnId: item.id,
          type: item.type,
          value: item.type == "多选型" ? item.answer1.join(",") : item.answer,
        });
      });
      // 反馈
      if (this.currentOperaterecord.status == "简历推荐") {
        this.loadFeedBack(recommendStatus, {
          id: this.currentOperaterecord.id,
          recommendStatus: this.feedBackStatus,
          commentRecord: commentRecord,
        },index);
      } else if (this.currentOperaterecord.status == "客户面试") {
        this.loadFeedBack(interviewStatus, {
          id: this.currentOperaterecord.id,
          interviewStatus: this.feedBackStatus,
          commentRecord: commentRecord,
        },index);
      } else if (this.currentOperaterecord.status == "Offer") {
        this.loadFeedBack(offerStatus, {
          id: this.currentOperaterecord.id,
          offerStatus: this.feedBackStatus,
          commentRecord: commentRecord,
        },index);
      } else if (this.currentOperaterecord.status == "入职") {
        this.loadFeedBack(entryStatus, {
          id: this.currentOperaterecord.id,
          entryStatus: this.feedBackStatus,
          commentRecord: commentRecord,
        },index);
      }
    },
    loadFeedBack(apiName, queryDate,index) {
      apiName(queryDate).then((res) => {
        if (res.success) {
          this.loadList();
        }else{
          this.closePop(index)
        }
      });
    },
    closePop(index){
      // console.log(scope,scope._self,scope._self.$refs[`popover-${scope.$index}`]),
                               this.$refs[`popover-${index}`][0].doClose()
    },
    editRecode(val) {
      let _this = this;
      _this.projectResumeId3 = this.projectResumeId;
      _this.resumeMemberId = this.projectResumeId;
      this.currentOperaterecord = val;
      if (
        val.status !== "客户面试" &&
        val.status !== "Offer" &&
        val.status !== "简历推荐"
      ) {
        this.dialogVisible = true;
      }
      this.titName = "编辑" + val.status;
      if (val.status == "简历推荐") {
        this.isDrawerVisible = true;
        this.isRecommend = true;
        this.iseditD = true;
        setTimeout(function () {
          _this.$refs.saveResumeRecommendation.loadEditInfo(val);
        }, 100);
      } else if (val.status == "客户面试") {
        this.isDrawerVisible = true;
        this.iseditD = true;
        this.isInterview = true;
        setTimeout(function () {
          _this.$refs.saveInterview.loadEditInfo(val);
        }, 100);
      } else if (val.status == "Offer") {
        this.isDrawerVisible = true;
        this.isOffer = true;
        setTimeout(function () {
          _this.$refs.saveOffer.loadEditInfo(val);
        }, 100);
      } else if (val.status == "背景调查") {
        this.isBackgroundSurvey = true;
        setTimeout(function () {
          _this.$refs.saveBackgroundSurvey.loadEditInfo(val);
        }, 100);
      } else if (val.status == "入职") {
        this.isEnrty = true;
        setTimeout(function () {
          _this.$refs.saveEntry.loadEditInfo(val);
        }, 100);
      }
    },
    getOperaterecordPageList(row) {
      this.projectResumeId = row.id;
      this.projectManageId = row.projectManagerId; //项目经理id
      getOperaterecordPageList({ projectResumeId: row.id }).then((res) => {
        if (res.success) {
          this.operationLogList = res.result;
        }
      });
    },
    cancel() {
      this.isRecommend = false;
      this.isInterview = false;
      this.isOffer = false;
      this.isBackgroundSurvey = false;
      this.isEnrty = false;
      this.isFail = false;
      this.isaddMarkResume = false;
      this.isaddMark = false;
      this.ishappyNews = false;
      this.isWhiteCollarWorker=false;
      this.isfinancialTask = false;
      this.isfinancial = false;
      this.iswriteReport = false;
      this.dialogVisibleEmail = false;
      this.resumeDialogVisible = false;
      this.isDrawerVisible = false;
      this.isReset = false;
      this.resetRuleForm.resetStatus = "";
      this.isshowleftContent = false;
      this.isAnnouncement=false
    },
    openDialog(val, item, index) {
      this.cancelOperateId = item.id;
      this.cancelOperateStatus = item.status;
      this.projectResumeId3 = item.id;
      this.resumeMemberId = item.id;
      this.getId = item.resumeId;
      this.resumeId = item.resumeId;
      this.projectId1 = item.projectId;
      this.financialTaskName = item.userName;
      this.isInnerInterview = false;
      this.ListData.forEach((item) => {
        this.$set(item, "cantralPopvisible", false);
      });
      val = val[1];
      let permTypes = this.getStore("permiss");
      this.currentOperaterecord = item; //获取当前选中项数据
      if (val == "创建财务任务") {
        // 判断是否可创建财务任务
        if (permTypes.includes("progream:index:task")) {
          //有权限
          // 判断人选是否为候选人
          if (
            item.resumeType == 2 ||
            item.resumeType == 0 ||
            item.resumeType == 4
          ) {
            let div =
              "<p style='font-weight:bold'>无法创建财务任务</p>" +
              "<div>原因：人选的类型不能为【cold call】，必须是【候选人】</div>";
            this.$confirm(div, "提示", {
              confirmButtonText: "完善人才",
              cancelButtonText: "取消",
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
              .then(() => {
                //跳简历编辑
                this.drawerdialogVisible = true;
              })
              .catch(() => {});
          } else {
            canCreateFinanceTask({
              projectResumeId: this.currentOperaterecord.id,
            }).then((res) => {
              if (res.success) {
                this.isfinancialTask = true;
                this.isfinancial = true;
              }
            });
          }
        } else {
          //无权限
          this.$alert(
            "您的角色未配置创建财务任务权限，如有需要请联系管理员配置",
            "提示",
            {
              confirmButtonText: "确定",
              customClass: "altCls",
              showClose: false,
              callback: (action) => {},
            }
          );
        }
      } else if (val == "简历推荐") {
        if (this.currentOperaterecord.resumeType == "2") {
          let div =
            "<p style='font-weight:bold'>无法推荐人选</p>" +
            "<div>原因：推荐人选的类型不能为【cold call】，必须是【候选人】</div>";
          this.$confirm(div, "提示", {
            confirmButtonText: "完善人才",
            cancelButtonText: "取消",
            customClass: "altCls",
            dangerouslyUseHTMLString: true,
          })
            .then(() => {
              // 跳简历编辑
              this.drawerdialogVisible = true;
            })
            .catch(() => {});
        } else {
          if (this.currentOperaterecord.canOperate) {
            this.width = "40%";
            this.isRecommend = true;
            this.iseditD = false;
            this.isDrawerVisible = true;
          } else {
            this.$message.error("该候选人已被推荐到其他项目");
          }
        }
      } else if (val == "Offer") {
        this.offerGoodnewsDefaultData();
      } else if (val == "候选人沟通") {
        // this.popShow=true
        this.tablechioceIndex = index;
        if (this.currentOperaterecord.resumeType == "2") {
          let div =
            "<p style='font-weight:bold;text-align:center'>当前备注人选为cold call ，是否补充为候选人？</p>" +
            "<div style='text-align:center'>（注意：cold call 的备注不计入KPI）</div>";
          this.$confirm(div, "提示", {
            confirmButtonText: "完善人才",
            cancelButtonText: "直接备注",
            customClass: "altCls",
            dangerouslyUseHTMLString: true,
          })
            .then(() => {
              this.drawerdialogVisible = true;
            })
            .catch(() => {
              this.dialogVisibleSingle = true;
              this.isaddMarkResume = true;
              this.dialogVisibleType = false;
              clearInterval(this.timer); //清除计时器
              this.$nextTick(() => {
              });
            });
        } else {
          this.dialogVisibleSingle = true;
          this.isaddMarkResume = true;
          this.dialogVisibleType = false;
          clearInterval(this.timer); //清除计时器
          this.$nextTick(() => {
          });
        }
      } else if (val == "F2F面试") {
        // debugger
        this.textFoo = "编辑备注";
        this.isInnerInterview = true;
        this.typeContent = "F2F面试";
        this.dialogVisible = false;
        if (
          item.resumeType == 2 ||
          item.resumeType == 0 ||
          item.resumeType == 4
        ) {
          // debugger
          this.dialogVisibleSingle = false;
          this.dialogVisibleType = true;
        } else {
          this.dialogVisibleSingle = true;
          this.isaddMark = true;
          this.dialogVisibleType = false;
        }
      } else if (val == "移出项目") {
        this.$confirm("请确认是否移出项目", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          type: "warning",
        })
          .then(() => {
            removeProject({ id: item.id }).then((res) => {
              if (res.success) {
                this.$message({
                  type: "success",
                  message: "移出成功!",
                });
                this.loadList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消移出",
            });
          });
      } else if (val !== "简历推荐" && val !== "Offer" && val !== "客户面试") {
        this.dialogVisible = true;
      }
      if (val == "F2F面试") {
        this.titName = "F2F面试";
      } else if (val == "候选人沟通") {
        this.titName = "添加备注";
      } else {
        this.titName = val;
      }

      if (val == "客户面试") {
        this.isInterview = true;
        this.iseditD = false;
        this.isDrawerVisible = true;
      } else if (val == "Offer") {
      } else if (val == "背景调查") {
        this.isBackgroundSurvey = true;
      } else if (val == "入职") {
        this.dialogVisible = false;
        canCreateFinanceTask({
          projectResumeId: this.currentOperaterecord.id,
        }).then((res) => {
          if (res.success) {
            this.dialogVisible = true;
            this.isEnrty = true;
          }
        });
      } else if (val == "内部面试") {
        this.textFoo = "内部面试";
        this.dialogVisible = false;
        this.dialogVisibleSingle = false;
        this.isInnerInterview = true;
        this.typeContent = "内部面试";
        if (
          item.resumeType == 2 ||
          item.resumeType == 0 ||
          item.resumeType == 4
        ) {
          // debugger
          this.dialogVisibleSingle = false;
          this.dialogVisibleType = true;
        } else {
          this.dialogVisibleSingle = true;
          this.isaddMark = true;
          this.dialogVisibleType = false;
        }
      } else if (val == "写报告") {
        this.iswriteReport = true;
        this.getCommonTemplateReportList();
      } else if (val == "重置") {
        this.isReset = true;
        this.getResetList();
      } else if (val == "撤销") {
        this.dialogVisible = false;
        this.$confirm(
          "撤销后将回退到上一环节，并且上一次操作不计入KPI，确认撤销?",
          "撤销",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            customClass: "altCls",
            type: "warning",
          }
        )
          .then(() => {
            this.cancelOperate();
          })
          .catch(() => {});
      }
      this.quickValue = [];
    },
    offerGoodnewsDefaultData() {
      let _this = this;
      offerGoodnewsDefaultData({
        projectResumeId: _this.currentOperaterecord.id,
      }).then((res) => {
        if (res.success) {
          // 判断人选是否为候选人
          haveSend({
            projectResumeId: _this.currentOperaterecord.id,
          }).then((res1) => {
            if (res1.success) {
              if (res1.result) {
                this.isAnnouncement=false
                 // if(res.result.businessType=='HEAD_HUNTER'){
                if(res.result.businessType=='WHITE_COLLAR'){
                    _this.isWhiteCollarWorker=true
                    _this.isfinancialTask = true;
                  }else{
                _this.isOffer = true;
                _this.isDrawerVisible = true;
                setTimeout(function () {
                  _this.$refs.saveOffer.loadEditInfo();
                }, 100);
              }
              } else {
                this.isAnnouncement=true
                if(res.result.businessType=='WHITE_COLLAR'){
                    _this.isWhiteCollarWorker=true
                    _this.isfinancialTask = true;
                  }else{
                // 判断人选是否为候选人
                _this.ishappyNews = true;
                _this.isfinancialTask = true;
                  }
              }
            }
          });
        }
      });
    },
    //重置
    getResetList() {
      getResetStatus({
        id: this.currentOperaterecord.id,
      }).then((res) => {
        if (res.success) {
          this.resetList = res.result;
        }
      });
    },
    // 撤销
    cancelOperate() {
      cancelOperate({
        projectResumeId: this.cancelOperateId,
        status: this.cancelOperateStatus,
      }).then((res) => {
        if (res.success) {
          this.$message({
            message: "撤销成功",
            type: "success",
          });
          this.loadList();
        }
      });
    },
    loadTagList(type) {
      //标签列表
      getLabelOptions().then((res) => {
        if (res.success) {
          this.TagList = res.result;
        }
      });
    },
    searchTagBtn() {
      let text = this.searchTag;
      let data = [];
      if (text) {
        this.addTypeList.forEach((item, index) => {
          item.labels.forEach((i, index) => {
            if (i.name.toUpperCase().match(text.toUpperCase())) {
              let val = {
                name: "",
                type: "",
                id: "",
                category: "技能标签",
                typeName: "",
              };
              val.id = i.id;
              val.name = i.name.replaceAll(
                text,
                '<font color="#ff3f3f">' + text + "</font>"
              );
              val.typeName = item.name;
              val.type = "技能标签 > " + item.name + " > " + i.name;
              data.push(val);
            }
          });
        });
      }
      this.serchChoiceList = data;
      this.searchHoverContentIsShow = true;
    },
    delSearch(id) {
      //删除订阅搜索
      this.$confirm("确定删除该订阅?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deleteSearchById(id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadOrderSearchList();
              if (this.actived == id) {
                this.$router.push({
                  path: "/Pipeline",
                });
                this.clearSearch();
                this.actived = "团队Pipeline";
                this.searchInfo.quickType = "团队Pipeline";
                this.loadList();
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    saveFavorite() {
      this.saveloading = true;
      addToFavorite(this.favoriteList).then((res) => {
        this.fileManagement = false;
        this.saveloading = false;
        this.$message.success(res.message);
      });
    },
    getLength(val) {
      if (val) {
        this.isShowSaveBtn = true;
      } else {
        this.isShowSaveBtn = false;
      }
    },
    // 打标签
    addTag() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择需要打标签的项");
        return false;
      }
      this.getLabelCategoryOptions();
      this.dialogVisibleOfTag = true;
      this.searchTag = "";
      this.searchHoverContentIsShow = false;
      this.createTag.labelIds = [];
      this.createTag.resumeIds = [];
      this.multipleSelection.forEach((item, index) => {
        this.createTag.resumeIds[index] = item.resumeId;
      });
    },
    //获取标签选项
    getLabelCategoryOptions() {
      getLabelCategoryOptions().then((res) => {
        if (res.success) {
          res.result.forEach((item) => {
            item.isActive = false;
            item.hitsNums = 0;
          });
          this.addTypeList = res.result;
          if (this.addTypeList.length > 0) {
            this.addTypeList[0].isActive = true;
            this.choiceTypeName = this.addTypeList[0].name;
            this.newTagList = this.addTypeList[0].labels;
            this.newTagSerchList = this.addTypeList[0].labels;
          }
        }
      });
    },
    //选中标签类别
    chioceType(data) {
      this.choiceTypeName = data.name;
      this.addTypeList.forEach((item) => {
        if (item.name == data.name) {
          item.isActive = true;
          this.newTagList = data.labels;
          this.newTagSerchList = data.labels;
        } else {
          item.isActive = false;
        }
      });
    },
    saveTags() {
      this.$refs.labelManagement.save();
    },
    addToFavorite() {
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.fileManagement = true;
        this.isCheckbox.edit = true;
      } else {
        this.$message.error("请先选择需要加入的项");
      }
    },
    childByValue(childValue) {
      this.favoriteList.favoriteIds = childValue;
    },
    addPro() {
      //加入项目
      this.startTimeStamp = Math.round(new Date().getTime() / 1000);
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择需要加入的项");
        return false;
      }
      let Protect = [];

      this.resumeIds = []; //可以提交的id
      let ids = [];
      this.multipleSelection.forEach((i) => {
        if (i.isLocked) {
          Protect.push(i.userName);
        } else {
          this.resumeIds.push(i.resumeId);
        }
        ids.push(i.resumeId);
      });
      statisticsResumeAddProject({
        resumeIds: ids,
        entry: "团队Pipeline列表",
      }).then();
      if (Protect.length > 0) {
        if (Protect.length == this.multipleSelection.length) {
          this.$alert("操作候选人均触发候选人保护机制，无法加入项目", "提示", {
            customClass: "altCls",
            confirmButtonText: "确定",
            callback: (action) => {
              if (action == "confirm") {
                this.handleStatisticsClickFunctionEscape(
                  "点击了【全部触发保护机制提示弹框】的确定"
                );
              } else {
                this.handleStatisticsClickFunctionEscape(
                  "点击了【全部触发保护机制提示弹框】的X"
                );
              }
            },
          });
          return false;
        } else {
          let ProtectName = Protect.join("、");
          this.$confirm(
            '<p style="font-weight:bold">部分候选人触发候选人保护机制无法加入项目，点击下方【继续】按钮，加入项目时会自动忽视这部分候选人</p><p>触发候选人保护机制的候选人姓名：' +
              ProtectName +
              "</p>",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            }
          )
            .then(() => {
              this.dialogaddProgreamVisible = true;
            })
            .catch(() => {
              this.handleStatisticsClickFunctionEscape(
                "点击了【部分触发保护机制提示弹框】的X"
              );
            });
        }
      } else {
        this.dialogaddProgreamVisible = true;
      }
    },
    blurCompany(e) {
      setTimeout(() => {
        this.isShowCompany = false;
      }, 500);
    },
    loadColumnInList(type) {
      //行业列表
      this.ColumnInList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
          ++this.isResouceShow;
        }
      });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.searchInfo.page.size = val;
      this.searchInfo.page.current = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.searchInfo.page.current = val;
      this.loadList();
    },
    loadColumnPosList() {
      //职能列表
      this.ColumnPosList = [];
      getCommonColumnList({ type: "职能" }).then((res) => {
        if (res.success) {
          this.ColumnPosList = res.result;
          ++this.isResouceShow;
        }
      });
    },
    loadColumnCityList(type) {
      //城市列表
      this.ColumnCityList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.ColumnCityList = res.result;
          ++this.isResouceShow;
        }
      });
    },
    educationsChanged(e) {
      this.loadList();
    },
    workExperienceChanged() {
      this.loadList();
    },
    clearSearch() {
      this.searchInfo = {
        channels: [], //渠道
        checkSchoolType: false, //是否按学校类型搜索
        company: "", //公司名称
        contact: "", //联系方式
        projectStatus: ["进行中"], //项目状态
        contactStatus: [],
        createEndTime: "",
        createStartTime: "",
        educations: ["UnLimit"], //教育经历 [UnLimit,本科及以上, 硕士及以上, 博士及以上, 大专及以上, 高中及以下]
        firstDegrees: [], //第一学历
        flags: [],
        createdUserIds: [],
        forwardLocations: [], //期望城市
        forwardTitle: "", //期望职位
        forwardVocation: "", //期望职能
        forwardVocations: [], //期望行业
        functionIds: [], //职能
        highAimSalary: "", //期望最多月薪
        id: "", //简历编号
        isAllCompany: false, //是否全部公司
        isAllTitle: false, //是否全部职位
        is_tongzhao: false, //是否统招
        jobStatus: [], //求职状态
        lowAimSalary: "", //期望最少月薪
        married: [], //婚姻状况
        maxAge: "", //最大年龄
        minAge: "", //最小年龄
        nowLocations: [], //现居住城市
        ownerUserIds: [], //人选拥有者
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "lastModifiedTime",
            },
          ],
          current: 1,
          size: 20,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
        projectName: "", //项目名称
        projectId: "",
        quickType: "团队Pipeline",
        resumeType: "", //候选人类型：1-候选人，2-code call
        sex: "", //性别
        status: null,
        searchKey: "",
        tagIds: [],
        title: "", //职位名称
        userName: "", //姓名
        vocationIds: [], //行业Id
        workExperiences: ["UnLimit"], //工作年限 [UnLimit, OneToThree, ThreeToFive, FiveToTen, TenMore]
        showDisabledUser: false,
        consultantIds: [],
        recommendStartTime:'',
recommendEndTime:'',
firstInterviewStartTime:'',
firstInterviewEndTime:'',
offerStartTime:'',
offerEndTime:'',
entryStartTime:'',
entryEndTime:'',
statusList:[]
      };
      this.timeVal = "";
      this.TimeJoinVal=[]
this.timeRecommendVal=[]
this.timeFirstInterviewVal=[]
this.timeOfferVal=[]
this.timeEntryVal=[]
      this.rangTime = []; //清空查询时间
      this.ranglastModifiedTime = [];
    },
    orderSearch() {
      //订阅搜索
      this.subscribeSearchDialog = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    saveSubscribe(formName) {
      this.isSubscribeSearch = true;
      //保存搜索
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.subscribeForm.searchList = this.searchInfo;
          SearchAddOrEdit(this.subscribeForm).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.subscribeSearchDialog = false;
              this.loadOrderSearchList();
              this.resetForm("subscribeForm");
              this.isSubscribeSearch = false;
            }
          });
        } else {
          this.isSubscribeSearch = false;
          return false;
        }
      });
    },
    loadOrderSearchList() {
      //加载订阅搜索
      GetSearchPage({ type: "团队Pipeline" }).then((res) => {
        if (res.success) {
          this.OrderSearchList = res.result;
        }
      });
    },
    blurTitle(e) {
      setTimeout(() => {
        this.isShowTitle = false;
      }, 500);
    },
    isMoreCityClk() {
      this.cascaderCityKey = 0;
    },
    submitForm() {
      if (this.rangTime && this.rangTime.length > 0) {
        this.searchInfo.createStartTime = this.rangTime[0];
        this.searchInfo.createEndTime = this.rangTime[1];
      } else {
        this.searchInfo.createStartTime = "";
        this.searchInfo.createEndTime = "";
      }
      if (this.ranglastModifiedTime && this.ranglastModifiedTime.length > 0) {
        this.searchInfo.updateStartTime = this.ranglastModifiedTime[0];
        this.searchInfo.updateEndTime = this.ranglastModifiedTime[1];
      } else {
        this.searchInfo.updateStartTime = "";
        this.searchInfo.updateEndTime = "";
      }
      if (
        this.searchInfo.maxAge &&
        this.searchInfo.minAge &&
        this.searchInfo.maxAge < this.searchInfo.minAge
      ) {
        this.$message.error("请填写正确的年龄区间");
        return false;
      }
      if (
        this.searchInfo.highAimSalary &&
        this.searchInfo.lowAimSalary &&
        this.searchInfo.highAimSalary < this.searchInfo.lowAimSalary
      ) {
        this.$message.error("请填写正确的薪资区间");
        return false;
      }
      this.trimInput();
      this.$refs["searchInfo"].validate((valid) => {
        if (valid) {
          this.searchInfo.page.current = 1;
          this.searchInfo.recordId = "";
          this.loadList();
        }
      });
    },
    getTime(val) {
      this.timeVal = val;
      this.submitForm();
    },
    getJoinTime(val) {
      this.rangTime = val;
      this.submitForm();
    },
    getRecommendTime(val) {
      this.timeRecommendVal = val;
      this.submitForm();
    },
    getFirstInterviewTime(val) {
      this.timeFirstInterviewVal = val;
      this.submitForm();
    },
    getOfferTime(val) {
      this.timeOfferVal = val;
      this.submitForm();
    },
    getEntryTime(val) {
      this.timeEntryVal = val;
      this.submitForm();
    },

    trimInput(val) {
      if (val) {
        this.searchInfo.searchKey = val.trim();
      }
    },
    loadUserBase() {
      //获取用户列表
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.searchUserList = res.result;
        }
      });
    },
    changeItem(item) {
      this.searchInfo.page.current = 1;
      if (item.isActive) return;
      this.currentItem.isActive = false;
      this.$set(item, "isActive", true);
      this.currentItem = item;
      this.searchInfo.status = item.status;
      this.loadList();
    },
    loadList() {
      this.cancel();
      this.ListData = [];
      this.searchInfo.createStartTime = this.timeVal ? this.timeVal[0] : "";
      this.searchInfo.createEndTime = this.timeVal ? this.timeVal[1] : "";
      this.searchInfo.updateStartTime = this.ranglastModifiedTime
        ? this.ranglastModifiedTime[0]
        : "";
      this.searchInfo.updateEndTime = this.ranglastModifiedTime
        ? this.ranglastModifiedTime[1]
        : "";
      this.searchInfo.joinStartTime = this.rangTime ? this.rangTime[0] : "";
      this.searchInfo.joinEndTime = this.rangTime ? this.rangTime[1] : "";
      this.searchInfo.recommendStartTime = this.timeRecommendVal ? this.timeRecommendVal[0] : "";
      this.searchInfo.recommendEndTime = this.timeRecommendVal ? this.timeRecommendVal[1] : "";
      this.searchInfo.firstInterviewStartTime = this.timeFirstInterviewVal ? this.timeFirstInterviewVal[0] : "";
      this.searchInfo.firstInterviewEndTime = this.timeFirstInterviewVal ? this.timeFirstInterviewVal[1] : "";
      this.searchInfo.offerStartTime = this.timeOfferVal ? this.timeOfferVal[0] : "";
      this.searchInfo.offerEndTime = this.timeOfferVal ? this.timeOfferVal[1] : "";
      this.searchInfo.entryStartTime = this.timeEntryVal ? this.timeEntryVal[0] : "";
      this.searchInfo.entryEndTime = this.timeEntryVal ? this.timeEntryVal[1] : "";
      statusInfoWithHistory(this.searchInfo).then((res) => {
        if (res.success) {
          this.list = res.result;
          if (!this.currentItem) {
            if (!this.statusType) {
              this.list[7].isActive = true;
              this.currentItem = this.list[7];
            } else {
              switch (this.statusType) {
                case "all":
                  this.list[7].isActive = true;
                  this.currentItem = this.list[7];
                  break;
                case "join":
                  this.list[0].isActive = true;
                  this.currentItem = this.list[0];
                  break;
                case "recommend":
                  this.list[1].isActive = true;
                  this.currentItem = this.list[1];
                  break;
                case "interview":
                  this.list[2].isActive = true;
                  this.currentItem = this.list[2];
                  break;
                case "offer":
                  this.list[3].isActive = true;
                  this.currentItem = this.list[3];
                  break;
                case "success":
                  this.list[5].isActive = true;
                  this.currentItem = this.list[5];
                  break;
              }
            }
          } else {
            for (let i in this.list) {
              if (this.list[i].status == this.currentItem.status) {
                this.list[i].isActive = true;
                this.currentItem = this.list[i];
              }
            }
          }
          this.getList();
        }
      });
    },
    handleCont(row) {
      let options = JSON.parse(JSON.stringify(this.options));
      if (row.status == "加入项目") {
        options[0].options[0].disabled = false;
        if (this.useId == row.createdUserId) {
          options[1].options[5].disabled = false;
        }
      } else {
        if (row.status == "简历推荐") {
          if (row.isFail) {
            options[0].options[4].disabled = false;
          } else {
            options[0].options[1].disabled = false;
          }
          options[0].options[5].disabled = false;
        } else if (row.status == "客户面试") {
          if (row.isFail) {
            options[0].options[4].disabled = false;
          } else {
            options[0].options[1].disabled = false;
            options[0].options[2].disabled = false;
          }
          options[0].options[5].disabled = false;
        } else if (row.status == "Offer") {
          if (row.isFail) {
            options[0].options[4].disabled = false;
          } else {
            options[0].options[3].disabled = false;
          }
        } else if (row.status == "入职") {
          if (row.isFail) {
            options[0].options[4].disabled = false;
          } else {
            options[1].options[4].disabled = false; // 创建财务任务
          }
          options[0].options[5].disabled = false;
        } else if (row.status == "成功") {
          options[1].options[4].disabled = false; // 创建财务任务
        }
      }
      this.handleOpts = options;
      this.ListData.forEach((item) => {
        if (row.id == item.id && !row.cantralPopvisible) {
          item.cantralPopvisible = true;
        } else {
          item.cantralPopvisible = false;
        }
      });
    },
    getList() {
      //获取pipeline列表
      this.dialogVisible = false;
      this.dialogVisibleSingle = false;
      this.listLoading = true;
      this.searchInfo.createStartTime = this.timeVal ? this.timeVal[0] : "";
      this.searchInfo.createEndTime = this.timeVal ? this.timeVal[1] : "";
      this.searchInfo.updateStartTime = this.ranglastModifiedTime
        ? this.ranglastModifiedTime[0]
        : "";
      this.searchInfo.updateEndTime = this.ranglastModifiedTime
        ? this.ranglastModifiedTime[1]
        : "";
      this.searchInfo.joinStartTime = this.rangTime ? this.rangTime[0] : "";
      this.searchInfo.joinEndTime = this.rangTime ? this.rangTime[1] : "";
      this.searchInfo.recommendStartTime = this.timeRecommendVal ? this.timeRecommendVal[0] : "";
      this.searchInfo.recommendEndTime = this.timeRecommendVal ? this.timeRecommendVal[1] : "";
      this.searchInfo.firstInterviewStartTime = this.timeFirstInterviewVal ? this.timeFirstInterviewVal[0] : "";
      this.searchInfo.firstInterviewEndTime = this.timeFirstInterviewVal ? this.timeFirstInterviewVal[1] : "";
      this.searchInfo.offerStartTime = this.timeOfferVal ? this.timeOfferVal[0] : "";
      this.searchInfo.offerEndTime = this.timeOfferVal ? this.timeOfferVal[1] : "";
      this.searchInfo.entryStartTime = this.timeEntryVal ? this.timeEntryVal[0] : "";
      this.searchInfo.entryEndTime = this.timeEntryVal ? this.timeEntryVal[1] : "";
      this.ListData = [];
      getProjectresumePageList(this.searchInfo).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.searchInfo.page.total = res.result.total;
          this.ListData.forEach((item) => {
            this.$set(item, "cantralPopvisible", false);
          });
          this.screenLoading = false;
        }
        this.listLoading = false;
        this.screenLoading = false;
      });
    },
    setTableData(data) {
      this.dialogVisible = false;
      this.dialogVisibleSingle = false;
      this.ListData[this.tablechioceIndex].remark =
        this.ListData[this.tablechioceIndex].remark + 1;
    },
    getCommonTemplateReportList() {
      //获取报告
      getCommonTemplateReportList().then((res) => {
        if (res.success) {
          this.optionsReport = res.result;
        }
      });
    },
    searchCus(val, type) {
      if (this.actived != val) {
        this.actived = val;
        this.sortCheck = null;
        this.sortType = "";
        this.currentItem = "";
        this.clearSearch();
        if (val == "我负责的候选人") {
          this.$router.push({
            path: "/Pipeline",
          });
          return;
        } else if (val == "我offer的候选人") {
          this.$router.push({
            path: "/Pipeline/offerResume",
          });
          return;
        } else if (val == "我提交的审批") {
          this.$router.push({
            path: "/Pipeline/approvalSubmit",
          });
          return;
        } else if (val == "团队Pipeline") {
          this.$router.push({
            path: "/Pipeline/teamPipeline",
          });
          return;
        }
        switch (type) {
          case "order":
            this.$router.push({
              path: "/Pipeline/teamPipeline",
              query: {
                order: val,
              },
            });
            break;
        }
      }
    },
    canOperate() {
      canOperate({ id: this.projectId }).then((reqs) => {
        if (reqs.success) {
          if (reqs.result) {
            this.projectHasPre = true;
          } else {
            this.projectHasPre = false;
          }
        }
      });
    },
    emailDrawerClose(val) {
      if (val == "isNoloading") {
        this.saveloading = false;
      } else {
        this.loadList();
        this.saveloading = false;
        this.isDrawerVisible = false;
        this.isRecommend = false;
        this.isInterview = false;
        this.iseditD = false;
      }
    },
    // 发邮件
    addEmail() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择需要发邮件的项");
        return false;
      }
      this.dialogVisibleOfEmail = true;
      this.resumeIds = [];
      this.multipleSelection.forEach((item, index) => {
        this.resumeIds[index] = item.resumeId;
      });
    },
    saveEmail() {
      this.saveloading = true;
      this.$refs.sendEmailFrom1
        .saveEmail("emailform", this.interviewForm)
        .then(() => {
          this.saveloading = false;
        })
        .then(() => {
          this.saveloading = false;
        });
    },
    changeLoading(val) {
      if (val == "isNoloading") {
        this.saveloading = false;
      } else {
        this.saveloading = false;
        this.loadList();
        this.dialogVisibleOfEmail = false;
      }

      // }
    },
    //置顶取消置顶
    handleTop(istop, id) {
      let rquest = null;
      if (istop == 1) {
        rquest = cancelTopProjectResume;
      } else {
        rquest = setTopProjectResume;
      }
      rquest({ projectResumeId: id }).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.loadList();
        }
      });
    },
    // 跳转到详情
    goResumeFoo(id) {
      this.isShowDetail = true;
      this.mouseoverDialog = false;
      this.resumeId = id;
      this.hoverInfo1.hover = true;
    },
    getCallMobileInfo(params) {
      this.isShowDetail = false;
      this.loadList();
    },
    //打开新窗口
    goNewPage(id) {
      statisticsResumeNewWindow({ resumeId: id }).then();
      let newpage = this.$router.resolve({
        path: "/resume/detailPage",
        query: { resumeId: id },
      });
      window.open(newpage.href, "_blank");
    },
    // 显示隐藏侧边栏
    showleftContent(id) {
      this.ListData1 = this.ListData;
      this.ListData1.forEach((i) => {
        if (i.resumeId == id) {
          i.isChoice = true;
        } else {
          i.isChoice = false;
        }
      });
      this.isshowleftContent = !this.isshowleftContent;
    },
    //更新数据
    updata(id) {
      this.ListData1.forEach((i) => {
        if (i.resumeId == id) {
          i.isChoice = true;
        } else {
          i.isChoice = false;
        }
      });
      this.isShowDetail = true;
      this.isShowDetail1 = false;
      this.time = true;
      this.mouseoverDialog = false;
      this.resumeId = id;
      this.hoverInfo1.hover = true;
      this.$refs.resumeDetails.reflsh(id);
    },
    //表格颜色
    tableColor({ row, rowIndex }) {
      let stylejson = {};
      if (row.isTop == 1) {
        stylejson.background = "#ffefef";
        return stylejson;
      } else {
        return "";
      }
    },
    //选中
    chioceData(i) {
      this.choiceTypeName = i.typeName;
      let list = [];
      list = this.createTag.labelIds;
      let item = [i.id];
      if (!this.createTag.labelIds.includes(i.id)) {
        if (list.length === 0) {
          this.createTag.labelIds = [...item];
        } else {
          this.createTag.labelIds = [...list, ...item];
        }
      } else {
        this.$message.error("标签已选");
      }
      this.searchTag = "";
      this.searchHoverContentIsShow = false;
    },
    changeShowDisabledUser(e) {
      //展示停用账户
      this.permissionTree();
    },
    changeCreateUserIds(e) {
      this.searchInfo.consultantIds = e.map((item) => {
        return (item = item[item.length - 1]);
      });
      this.submitForm();
    },
    handleVisibleChange(type, e) {
      if (type == 1) {
        if (!e) {
          this.keyOfPartnerIds++;
        }
      } else {
        if (!e) {
          this.keyOfCreateUserIds++;
        }
      }
    },
    // 获取用户的组织架构
    permissionTree() {
      permissionTree({
        type: "顾问",
        showDisableUser: this.searchInfo.showDisabledUser,
      }).then((res) => {
        if (res.success) {
          this.options2 = res.result;
        }
      });
    },
    handleStatisticsClickFunctionEscape(nextStep) {
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        entry: "团队pipeline",
        presentStep: "加入项目",
        startTimeStamp: this.startTimeStamp,
        nextStep: nextStep,
      }).then();
    },
  },
  destroyed() {
    this.clearSelect();
    this.canScroll();
  },
  watch: {
    "$route.query"(newValue, oldValue) {
      if (newValue.status) {
        //快捷菜单
        this.actived = newValue.status;
        this.searchInfo.quickType = newValue.status;
        this.loadList();
      } else {
        //订阅&&文件夹
        this.actived = newValue.order;
        if (newValue.order) {
          GetSearchById(this.actived).then((res) => {
            if (res.success) {
              this.searchInfo = res.result.searchList;
              this.loadList();
            }
          });
        } else {
          this.$router.replace({
            path: "/supplierAllBack",
            query: {
              path: "/Pipeline/teamPipeline",
            },
          });
        }
      }
    },
    "createTag.labelIds"(nVal, oVal) {
      let list = null;
      list = oVal.concat(nVal).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
      this.addTypeList.forEach((item) => {
        if (item.name == this.choiceTypeName) {
          item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
        } else {
          item.labels.forEach((i) => {
            if (i.id == list[0]) {
              item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
            }
          });
        }
      });
    },
  },
  mounted() {
    this.loadUserBase();
    this.noScroll();
  },
  created() {
    let permTypes = this.getStore("permiss");
    if (this.resumeNo) {
      //简历编号
      this.searchInfo.id = this.resumeNo;
    }
    if (this.proStatus) {
      this.statusType = this.proStatus;
      switch (this.statusType) {
        case "join":
          this.searchInfo.status = "加入项目";
          break;
        case "recommend":
          this.searchInfo.status = "简历推荐";
          break;
        case "interview":
          this.searchInfo.status = "客户面试";
          break;
        case "offer":
          this.searchInfo.status = "Offer";
          break;
      }
    }
    if (
      permTypes.includes("progream:index:downOwn") ||
      permTypes.includes("progream:index:downAll")
    ) {
      //判断是否有下载全部的权限
      if (permTypes.includes("progream:index:downAll")) {
        this.hasDownExportAll = true;
      }
      this.hasDownExport = true;
    } else {
      this.hasDownExport = false;
    }
    if (this.$route.query.status) {
      this.actived = this.$route.query.status;
      this.searchInfo.quickType = this.$route.query.status;
    }
    if (this.$route.query.order) {
      this.actived = this.$route.query.order;
      this.activeNames = ["1", "2"];
      GetSearchById(this.actived).then((res) => {
        if (res.success) {
          this.searchInfo = res.result.searchList;
        }
      });
    }
    if (permTypes.includes("Pipeline:teamPipeline")) {
      this.menuList = [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
        {
          name: "团队Pipeline",
          val: "团队Pipeline",
        },
      ];
    } else {
      this.menuList = [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
      ];
    }
    this.loadList();
    this.loadOrderSearchList();
    this.permissionTree(); //获取用户组织架构
  },
};
</script>
<style lang="scss">
@import "./unfold.less";
.statusTitle {
  .isborder {
    .el-collapse-item__wrap {
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.mouseoverDialog {
  .el-dialog__body {
    padding: 0;
  }
}
.tagList {
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }
}
.s_from_item {
  .el-form-item {
    width: 100%;
  }
}
.dialogFloatwindow.el-dialog__wrapper {
  left: auto;
  box-shadow: -4px 0 15px -1px #dcdcdc;
}
.floatwindow {
  width: 1300px;
  margin-top: 0 !important;
  margin-right: 0;
  height: 100vh;
  @media (max-width: 1366px) {
    width: 900px;
    // margin-top: 3% !important;
  }
}
.redrawerClass {
  .el-drawer__body {
    overflow: hidden;
  }
}
</style>
<style lang="less" scoped>
@import "../collapsed.less";
@import "./index.less";
.searchL {
  width: calc(100% - 190px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  // float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.ishanlder {
  &:hover {
    color: #e60012;
    border-color: #e60012;
  }
}
.feedBackCss {
  .feedBackStyle {
    .el-radio {
      height: 28px;
      line-height: 28px;
      &:hover {
        color: #e60012;
        border-color: #e60012;
      }
    }
  }
}
.searchPanel {
  .el-input__inner {
    min-width: auto;
  }
}
.l_box {
  // border-bottom:1px #ececec solid;
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #ededed;
    background: #ededed;
  }
  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #fff;
  }
}
.l_content {
  width: 200px;
  border-right: 1px #ececec solid;
  border-bottom: 1px #ececec solid;
  overflow: auto;
  height: calc(100vh - 120px);
  .l_c_tit {
    font-weight: bold;
    font-size: 15px;
    line-height: 30px;
    padding: 5px 20px;
    border-bottom: 1px #ececec solid;
  }
  .l_c_list {
    p {
      color: #606266;
      line-height: 25px;
      padding: 5px 20px;
    }
    p:hover {
      color: #526ecc;
      background: #ececec;
      text-decoration: none;
    }
  }
  .l_c_list_active {
    color: #526ecc !important;
    background: #ececec;
    text-decoration: none;
  }
}
</style>
